import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useGetCustomerTemplateQuery } from 'api/customerTemplateApi';

interface CheckboxState {
  showDiagramGigi: boolean;
  showBooking: boolean;
  showPenjualan: boolean;
  showPeringkat: boolean;
  showHistori: boolean;
  showFormTemplat: boolean;
  [key: string]: boolean;
}

interface CheckboxDetailPelangganProps {
  show: boolean;
  onHide: () => void;
  checkboxes: CheckboxState;
  onCheckboxChange: (updatedCheckboxes: CheckboxState) => void;
}

const CheckboxDetailPelanggan: React.FC<CheckboxDetailPelangganProps> = ({
  show,
  onHide,
  checkboxes,
  onCheckboxChange
}) => {
  const [checkedState, setCheckedState] = useState<CheckboxState>(checkboxes);

  const { data: templateData, refetch } = useGetCustomerTemplateQuery({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  useEffect(() => {
    const initialState = { ...checkboxes };
    templateData?.data?.result.forEach(template => {
      const templateKey = `show_template_${template.id}`;
      if (!(templateKey in initialState)) {
        initialState[templateKey] = false;
      }
    });
    setCheckedState(initialState);
  }, [checkboxes, templateData]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckedState(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleSave = () => {
    onCheckboxChange(checkedState);
    onHide();
  };

  const handleCancel = () => {
    setCheckedState(checkboxes);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleCancel} centered>
      <Modal.Header
        closeButton={false}
        className="d-flex justify-content-between p-4"
      >
        <Modal.Title>
          <h4 className="fw-bolder m-0">Opsi Menu Kustom</h4>
        </Modal.Title>
        <div className="d-flex gap-2">
          <Button
            variant="link"
            style={{ color: 'red' }}
            onClick={handleCancel}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <div>
            <h6>Menu:</h6>
            <Form.Check
              type="checkbox"
              label="Diagram Gigi"
              name="showDiagramGigi"
              checked={checkedState.showDiagramGigi}
              onChange={handleCheckboxChange}
              disabled
            />
            <Form.Check
              type="checkbox"
              label="Booking"
              name="showBooking"
              checked={checkedState.showBooking}
              onChange={handleCheckboxChange}
              disabled
            />
            <Form.Check
              type="checkbox"
              label="Penjualan"
              name="showPenjualan"
              checked={checkedState.showPenjualan}
              onChange={handleCheckboxChange}
              disabled
            />
            <Form.Check
              type="checkbox"
              label="Penilaian & Ulasan"
              name="showPeringkat"
              checked={checkedState.showPeringkat}
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Histori"
              name="showHistori"
              checked={checkedState.showHistori}
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Form Templat"
              name="showFormTemplat"
              checked={checkedState.showFormTemplat}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="mt-3">
            <h6>Templat:</h6>
            {templateData?.data?.result
              .filter(template => template.status)
              .map(template => (
                <Form.Check
                  key={template.id}
                  type="checkbox"
                  label={template.templateName}
                  name={`show_template_${template.id}`}
                  checked={
                    checkedState[`show_template_${template.id}`] || false
                  }
                  onChange={handleCheckboxChange}
                />
              ))}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CheckboxDetailPelanggan;
