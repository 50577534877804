import AdvanceTable from 'components/base/AdvanceTable';
import Badge from 'components/base/Badge';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ColumnDef } from '@tanstack/react-table';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { salesTableData, Sale } from 'data/e-commerce/Penjualan';

const grandTotal = salesTableData.reduce(
  (sum, record) => sum + record.total,
  0
);

const salesColumns: ColumnDef<Sale>[] = [
  {
    accessorKey: 'invoiceNumber',
    header: 'NO.INVOICE',
    cell: ({ row: { original } }) => original.invoiceNumber,
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    },
    footer: () => 'TOTAL'
  },
  {
    accessorFn: ({ status }) => status.label,
    header: 'STATUS',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge
          bg={status.type}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={<FeatherIcon icon={status.icon} size={12.8} className="ms-1" />}
        >
          {status.label}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'location',
    header: 'LOKASI',
    cell: ({ row: { original } }) => (
      <Link to="#!" className="fw-semibold">
        {original.location}
      </Link>
    ),
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'total',
    header: 'TOTAL',
    cell: ({ row: { original } }) => {
      const { total } = original;
      return (
        <span>
          {new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
          }).format(total)}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: ' pe-3' },
      cellProps: { className: '' }
    },
    footer: () => (
      <span className="fw-semibold">
        {new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR'
        }).format(grandTotal)}
      </span>
    )
  },
  {
    accessorKey: 'paymentMethod',
    header: 'METODE BAYAR',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'invoiceDate',
    header: 'TANGGAL INVOICE',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: { className: 'text-end white-space-nowrap' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown btnClassName="lh-1">
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '5%' } },
      cellProps: { className: 'text-end' }
    }
  }
];

const CustomerPenjualanTable = () => {
  const table = useAdvanceTable({
    data: salesTableData,
    columns: salesColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <h3 className="mt-4">Penjualan</h3>
      <div className="border-y border-translucent">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
          hasFooter={true}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default CustomerPenjualanTable;
