import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { Booking, bookingsTableData } from 'data/e-commerce/bookings'; // Pastikan data booking sudah benar

// Definisi kolom untuk tabel Booking
export const bookingsTableColumns: ColumnDef<Booking>[] = [
  {
    accessorKey: 'location',
    header: 'Lokasi',
    cell: ({ row: { original } }) => {
      const { location } = original;
      return (
        <Link to="#!" className="fw-semibold">
          {location}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'pe-3' }
    }
  },
  {
    id: 'status',
    accessorFn: ({ status }) => status.label,
    header: 'Status',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge
          bg={status.type} // Pastikan status.type adalah BadgeBg
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={<FeatherIcon icon={status.icon} size={12.8} className="ms-1" />}
        >
          {status.label}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'employee',
    header: 'Pegawai',
    meta: {
      headerProps: { style: { width: '25%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'service',
    header: 'Layanan',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'booking_time',
    header: 'Waktu Booking',
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-end' },
      cellProps: {
        className: 'text-body-tertiary fs-9 ps-4 text-end white-space-nowrap'
      }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown btnClassName="lh-1">
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '5%' } },
      cellProps: { className: 'text-end' }
    }
  }
];

const CustomerBookingTable = () => {
  const table = useAdvanceTable({
    data: bookingsTableData, // Pastikan ini mengacu pada data bookings
    columns: bookingsTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <h3 className="mt-4">Booking</h3>
      <div className="border-y border-translucent">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default CustomerBookingTable;
