import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { CustomerResponse } from 'api/response/CustomerResponse';
import { Link } from 'react-router-dom';

export const customersTablecolumns: ColumnDef<CustomerResponse>[] = [
  {
    accessorKey: 'firstName',
    header: 'Nama Pelanggan',
    cell: ({ row: { original } }) => {
      const { firstName, lastName, id } = original;
      return (
        <Link
          to={`/apps/e-commerce/admin/customer-details/${id}`}
          className="d-flex align-items-center gap-3 text-body-emphasis"
        >
          <p className="mb-0 fw-bold">
            {firstName} {lastName}
          </p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'emails',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const email = original.emails[0]?.emailAddress || 'Tidak Ada';
      return (
        <Link
          to={`mailto:${email}`}
          className="fw-semibold text-decoration-none"
        >
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'phoneNumbers',
    header: 'No. Telepon',
    cell: ({ row: { original } }) => {
      const phoneNumber = original.phoneNumbers[0]?.phoneNumber || 'Tidak Ada';
      return <span className="fw-semibold">{phoneNumber}</span>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  },
  {
    accessorKey: 'addresses',
    header: 'Kecamatan',
    cell: ({ row: { original } }) => {
      const district = original.addresses[0]?.state || 'Tidak Ada';
      return <span>{district}</span>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'joinDate',
    header: 'Tanggal Bergabung',
    cell: ({ row: { original } }) => {
      return new Date(original.joinDate).toLocaleDateString('id-ID');
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'px-3' },
      cellProps: { className: 'px-3 py-2' }
    }
  }
];

interface CustomersTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
}

const CustomersTable = ({
  onPageChange,
  currentPage,
  totalPages
}: CustomersTableProps) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
        }}
      />
      <AdvanceTableFooter
        pagination
        className="mt-3"
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default CustomersTable;
