/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  faCalendarPlus,
  faChevronDown,
  faEnvelope,
  faFileExport,
  faFileInvoiceDollar,
  faGear,
  faTrashAlt,
  faPen
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerNotesCard from 'components/cards/CustomerNotesCard';
import CustomerProfileCard from 'components/cards/CustomerProfileCard';
import InfoDataCard from 'components/cards/InfoDataCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import CustomerRatingsTable from 'components/tables/CustomerRatingsTable';
import { detailPelangganBreadcrumbItems } from 'data/commonData';
import diagramGigi from 'assets/img/customer-detail/diagram-gigi.png';
import noCheckedBox from 'assets/img/spot-illustrations/404-illustration.png';

import { informasiTambahan } from 'data/e-commerce/infoDataCustomers';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import DiagramGigi from 'components/cards/DiagramGigiCard';
import CustomerBookingTable from 'components/tables/CustomerBookingTable';
import CustomerPenjualanTable from 'components/tables/CustomerPenjualanTable';
import InformasiPribadiCard from 'components/cards/InformasiPribadiCard';
import CheckboxDetailPelanggan from 'components/modals/CheckboxDetailPelanggan';
import EmailPelangganModal from 'components/modals/EmailPelangganModal';
import AlamatKontakCard from 'components/cards/AlamatKontakCard';
import KontakDaruratCard from 'components/cards/KontakDaruratCard';
import NotaPelangganModal from 'components/modals/NotaPelangganModal';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import DownloadTableModal from 'components/modals/DownloadTableModal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import {
  useDeleteCustomerMutation,
  useGetCustomerByIdQuery
} from 'api/customerApi';
import DeleteCustomerModal from 'components/modals/DeleteCustomerModal';
import {
  useGetCustomerTemplateQuery,
  useGetCustomerTemplateFieldQuery,
  useGetCustomerTemplateFieldTypesQuery
} from 'api/customerTemplateApi';
import type {
  CustomerTemplateFieldDetail,
  CustomerTemplate,
  CustomerTemplateField
} from 'api/response/CustomerTemplateResponse';
import { DefaultCheckboxKeys } from 'types/checkbox';
import CustomerTemplateCard from 'components/cards/CustomerTemplateCard';

interface CheckboxState {
  showDiagramGigi: boolean;
  showBooking: boolean;
  showPenjualan: boolean;
  showPeringkat: boolean;
  showHistori: boolean;
  showFormTemplat: boolean;
  [key: string]: boolean;
}

const CustomerDetails = () => {
  const { id } = useParams(); // Ambil ID dari URL
  const navigate = useNavigate();
  const { data: response, refetch } = useGetCustomerByIdQuery(id || ''); // Provide empty string as fallback
  const [deleteCustomer] = useDeleteCustomerMutation();

  // State untuk menentukan apakah checkbox ditampilkan di halaman utama
  const [checkboxVisibility, setCheckboxVisibility] = useState<CheckboxState>({
    showDiagramGigi: true,
    showBooking: true,
    showPenjualan: true,
    showPeringkat: false,
    showHistori: false,
    showFormTemplat: false
  });

  // State untuk menentukan status centang checkbox di halaman utama
  const [checkboxChecked, setCheckboxChecked] = useState<CheckboxState>({
    showDiagramGigi: true,
    showBooking: true,
    showPenjualan: true,
    showPeringkat: false,
    showHistori: false,
    showFormTemplat: false
  });

  // State untuk menampilkan atau menyembunyikan modal
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showNotaModal, setShowNotaModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [templateValues, setTemplateValues] = useState<{ [key: string]: any }>(
    {}
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckboxChecked(prevChecked => ({
      ...prevChecked,
      [name]: checked
    }));
  };

  const openModal = () => setShowModal(true);
  const openEmailModal = () => setShowEmailModal(true);
  const openNotaModal = () => setShowNotaModal(true);
  const closeModal = () => setShowModal(false);
  const closeEmailModal = () => setShowEmailModal(false);
  const closeNotaModal = () => setShowNotaModal(false);

  // Modifikasi cara penggunaan query
  const { data: templateData } = useGetCustomerTemplateQuery({
    page: 1,
    limit: 10,
    orderBy: 'createdDate',
    direction: 'DESC'
  });

  // Tambahkan query untuk field types
  const { data: fieldTypes } = useGetCustomerTemplateFieldTypesQuery();
  // Tambahkan query untuk field fields
  const { data: templateFields } = useGetCustomerTemplateFieldQuery(
    Number(id) || 0
  );

  // Fungsi untuk render field dalam mode display
  const renderDisplayField = (field: CustomerTemplateFieldDetail) => {
    switch (field.fieldType.code) {
      case 'RADIO_BUTTONS': {
        const selectedOption = field.customerTemplateFieldOption.find(
          opt => opt.selectedChoice
        );
        return (
          <div key={field.id} className="mb-3">
            <p className="text-body-secondary mb-0">
              {selectedOption?.optionValue || '-'}
            </p>
          </div>
        );
      }
      case 'MULTIPLE_LINE_TEXT':
        return (
          <div key={field.id} className="mb-3">
            <p className="text-body-secondary mb-0">
              {templateValues[field.fieldName] || '-'}
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  // Fungsi untuk render field dalam mode edit
  const renderEditField = (field: CustomerTemplateFieldDetail) => {
    switch (field.fieldType.code) {
      case 'RADIO_BUTTONS':
        return (
          <Form.Group key={field.id} className="mb-3">
            <Form.Label>
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <div>
              {field.customerTemplateFieldOption.map(option => (
                <Form.Check
                  key={option.id}
                  type="radio"
                  label={option.optionValue}
                  name={`${field.fieldName}_${field.id}`}
                  id={`${field.fieldName}-${option.id}`}
                  checked={option.selectedChoice}
                  onChange={() => {
                    // Update state lokal hanya untuk field yang diubah
                    const updatedFields = localTemplateFields?.data?.map(f => {
                      if (f.id === field.id) {
                        return {
                          ...f,
                          customerTemplateFieldOption:
                            f.customerTemplateFieldOption.map(opt => ({
                              ...opt,
                              selectedChoice: opt.id === option.id
                            }))
                        };
                      }
                      // Kembalikan field lain tanpa perubahan
                      return f;
                    });

                    if (updatedFields) {
                      setTemplateFields(prev => {
                        if (!prev) return undefined;
                        return {
                          ...prev,
                          data: updatedFields
                        };
                      });
                    }
                  }}
                />
              ))}
            </div>
          </Form.Group>
        );

      case 'MULTIPLE_LINE_TEXT':
        return (
          <Form.Group key={field.id} className="mb-3">
            <Form.Label>
              {field.label}
              {field.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name={field.fieldName}
              value={templateValues[field.fieldName] || ''}
              onChange={handleInputChange}
              maxLength={field.fieldTextLength}
              placeholder={`Masukkan ${field.label}`}
            />
          </Form.Group>
        );

      default:
        return null;
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTemplateValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Tambahkan state untuk menyimpan template fields
  const [localTemplateFields, setTemplateFields] =
    useState<typeof templateFields>(templateFields);

  // Update local state ketika data dari API berubah
  useEffect(() => {
    if (templateFields) {
      setTemplateFields(templateFields);
    }
  }, [templateFields]);

  // Fungsi untuk handle save
  const handleSave = async () => {
    try {
      // Di sini Anda bisa menambahkan API call untuk menyimpan perubahan
      console.log('Saving changes:', localTemplateFields);

      showCustomAlert({
        icon: 'success',
        label: 'Berhasil',
        deskripsi: 'Data template berhasil diperbarui',
        buttonType: 'ok',
        onConfirm: () => {
          setIsEditing(false);
        }
      });
    } catch (error) {
      showCustomAlert({
        icon: 'error',
        label: 'Gagal',
        deskripsi: 'Terjadi kesalahan saat menyimpan data',
        buttonType: 'ok'
      });
    }
  };

  // Fungsi untuk handle cancel
  const handleCancel = () => {
    // Reset ke data awal
    if (templateFields) {
      setTemplateFields(templateFields);
    }
    setIsEditing(false);
  };

  // Tambahkan type guard untuk memvalidasi key
  const isValidKey = (
    key: string
  ): key is DefaultCheckboxKeys | `show_template_${string}` => {
    return (
      key.startsWith('show_template_') ||
      [
        'showDiagramGigi',
        'showBooking',
        'showPenjualan',
        'showPeringkat',
        'showHistori',
        'showFormTemplat'
      ].includes(key)
    );
  };

  // Helper function untuk mendapatkan label checkbox
  const getCheckboxLabel = (key: string) => {
    switch (key) {
      case 'showFormTemplat':
        return 'Form Templat';
      case 'showDiagramGigi':
        return 'Diagram Gigi';
      case 'showBooking':
        return 'Booking';
      case 'showPenjualan':
        return 'Penjualan';
      case 'showPeringkat':
        return 'Penilaian & Ulasan';
      case 'showHistori':
        return 'Histori';
      default:
        return '';
    }
  };

  // Kondisi untuk memeriksa apakah semua checkbox tidak dicentang
  const allUnchecked = Object.keys(checkboxVisibility).every(
    key =>
      !checkboxVisibility[key as keyof typeof checkboxVisibility] ||
      !checkboxChecked[key as keyof typeof checkboxChecked]
  );

  const handleDeleteClick = () => {
    refetch().then(() => {
      setShowDeleteModal(true);
    });
  };

  const handleConfirmDelete = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Data pelanggan akan dihapus',
      deskripsi: 'Apakah Anda yakin untuk menghapus data pelanggan?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          await deleteCustomer({
            notes: '', // Isi dengan notes dari modal jika diperlukan
            deletionReason: 'Customer Request',
            data: [Number(id)]
          }).unwrap();
          setShowDeleteModal(false);

          showCustomAlert({
            icon: 'success',
            label: 'Sukses!',
            deskripsi: 'Data pelanggan berhasil dihapus',
            buttonType: 'ok',
            onConfirm: () => {
              navigate('/apps/e-commerce/admin/customers');
            }
          });
        } catch (error) {
          toast.error('Gagal menghapus data pelanggan');
          console.error('Delete error:', error);
        }
      }
    });
  };

  const handleDownload = (format: string, selectedData: string[]) => {
    const doc = new jsPDF(format === 'portrait-pdf' ? 'p' : 'l', 'mm', 'a4');

    const tableData = selectedData
      .map(dataType => {
        switch (dataType) {
          case 'info-umum':
            return ['Informasi Umum', 'Data informasi umum...'];
          case 'diagram-gigi':
            return ['Diagram Gigi', 'Data diagram gigi...'];
          default:
            return [];
        }
      })
      .filter(data => data.length > 0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc as any).autoTable({
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255 }
    });

    doc.save('detail-pelanggan.pdf');

    showCustomAlert({
      icon: 'success',
      label: 'Sukses!',
      deskripsi: 'Data berhasil diunduh',
      buttonType: 'ok'
    });
  };

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  return (
    <div>
      <PageBreadcrumb items={detailPelangganBreadcrumbItems} />
      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Detail Pelanggan</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <Button
            variant="link"
            className="text-body px-0"
            onClick={() => setShowDownloadModal(true)}
          >
            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
            Unduh Data
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="dropdown-caret-none text-decoration-none px-1"
            >
              Tindakan Lainnya
              <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item href="#/action-1">Berlangganan</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Referensi</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Nonaktifkan</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant="outline-danger" onClick={handleDeleteClick}>
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
            Hapus Pelanggan
          </Button>
          <Button variant="outline-primary" onClick={openEmailModal}>
            <FontAwesomeIcon icon={faEnvelope} className="me-2" />
            Kirim Email
          </Button>
          <EmailPelangganModal
            show={showEmailModal}
            onClose={closeEmailModal}
          />
          <Button variant="primary" onClick={openNotaModal}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />
            Buat Nota
          </Button>
          <NotaPelangganModal show={showNotaModal} onClose={closeNotaModal} />
          <Button variant="primary">
            <FontAwesomeIcon icon={faCalendarPlus} className="me-2" />
            Atur Booking
          </Button>
        </Col>
      </Row>

      <Row className="g-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
          <Row className="g-3 mb-3">
            <CustomerProfileCard />
            <InformasiPribadiCard />
            <AlamatKontakCard />
            <KontakDaruratCard />
            <CustomerNotesCard />
          </Row>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={7}>
          <Row className="d-flex flex-column g-3">
            <div className="p-0 d-flex align-items-center justify-content-between">
              <div className="d-flex gap-3 justify-content-between">
                {Object.entries(checkboxVisibility)
                  .filter(([key]) => !key.startsWith('show_template_'))
                  .sort(([keyA], [keyB]) => {
                    if (keyA === 'showFormTemplat') return -1;
                    if (keyB === 'showFormTemplat') return 1;
                    return 0;
                  })
                  .map(([key, isVisible]) =>
                    isVisible && isValidKey(key) ? (
                      <div key={key} className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={key}
                          name={key}
                          checked={checkboxChecked[key]}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label text-primary"
                          htmlFor={key}
                        >
                          {getCheckboxLabel(key)}
                        </label>
                      </div>
                    ) : null
                  )}
              </div>
              <div>
                <Button
                  variant="primary"
                  onClick={openModal}
                  className="px-2 py-2"
                >
                  <FontAwesomeIcon icon={faGear} className="fs-8" />
                </Button>
              </div>
            </div>
            {/* Tampilkan pesan jika semua checkbox tidak dicentang */}
            {allUnchecked && (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={noCheckedBox}
                  alt="No selections"
                  className="img-fluid w-50"
                />
                <h5 className="text-body mt-3 text-center">
                  Anda dapat menampilkan menu tambahan <br />
                  dengan memilih opsi menu diatas.
                </h5>
              </div>
            )}
            {/* Display components based on checkbox state
            {checkboxVisibility.showInformasiTambahan &&
              checkboxChecked.showInformasiTambahan && (
                <InfoDataCard
                  title="Informasi Tambahan"
                  data={informasiTambahan}
                  editable={true}
                />
              )} */}
            {/* Render template cards */}
            {checkboxChecked.showFormTemplat &&
              templateData?.data?.result?.map(template => {
                const templateKey = `show_template_${template?.id}`;

                if (
                  checkboxVisibility[templateKey] &&
                  template?.customerTemplateFields?.length > 0
                ) {
                  return (
                    <CustomerTemplateCard
                      key={template.id}
                      template={template}
                      customerId={id || ''}
                    />
                  );
                }
                return null;
              })}
            {checkboxChecked.showDiagramGigi && (
              <Card className="h-100">
                <Card.Body>
                  <DiagramGigi diagramGigiSrc={diagramGigi} />
                </Card.Body>
              </Card>
            )}
            {checkboxVisibility.showBooking && checkboxChecked.showBooking && (
              <CustomerBookingTable />
            )}
            {checkboxVisibility.showPenjualan &&
              checkboxChecked.showPenjualan && <CustomerPenjualanTable />}
            {checkboxVisibility.showPeringkat &&
              checkboxChecked.showPeringkat && <CustomerRatingsTable />}
          </Row>
        </Col>
      </Row>

      {/* Modal untuk pengaturan checkbox */}
      <CheckboxDetailPelanggan
        show={showModal}
        onHide={closeModal}
        checkboxes={checkboxVisibility}
        onCheckboxChange={updatedVisibility =>
          setCheckboxVisibility(updatedVisibility)
        }
      />

      <DownloadTableModal
        show={showDownloadModal}
        onHide={() => setShowDownloadModal(false)}
        onDownload={handleDownload}
        title="Unduh Data"
        isCustomerDetails={true}
      />

      <DeleteCustomerModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        selectedCustomers={[
          {
            name: response?.data?.master?.names?.fullName || 'Nama Pelanggan'
          }
        ]}
        onDelete={handleConfirmDelete}
      />
    </div>
  );
};

export default CustomerDetails;
