import { useAddCustomerMutation } from 'api/customerApi';
import { CustomerCreateRequest } from 'api/request/CustomerCreateRequest';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { memberCreateBreadcrumbItems } from 'data/members';
import { useState } from 'react';
import { Spinner, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const CreateMember = () => {
  const [formData, setFormData] = useState<CustomerCreateRequest>({
    firstName: '',
    middleName: '',
    lastName: '',
    nik: '',
    gender: '',
    email: '',
    address: '',
    province: '',
    village: '',
    city: '',
    district: '',
    dateOfBirth: '',
    phoneNumber: ''
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addCustomer, { isLoading }] = useAddCustomerMutation();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // await addCustomer(formData!).unwrap();
      toast.success('Form submitted successfully!', {
        position: 'top-right'
      });
      navigate('/pages/members');
    } catch (err) {
      console.error('Failed to submit:', err);
      toast.error('Failed to Submit', {
        position: 'top-right'
      });
    }
  };
  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', // Mengatur posisi horizontal ke tengah
          alignItems: 'center', // Mengatur posisi vertikal ke tengah
          height: '100vh' // Mengisi tinggi viewport
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  return (
    <div>
      <PageBreadcrumb items={memberCreateBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Create Members</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData!.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formMiddleName">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                name="middleName"
                value={formData!.middleName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData!.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="name@example.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGroupBod">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              name="dateOfBirth"
              value={formData!.dateOfBirth}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formAddress">
            <Form.Label>NIK</Form.Label>
            <Form.Control
              placeholder="No. Ktp"
              name="nik"
              value={formData!.nik}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              placeholder="1234 Main St"
              name="address"
              value={formData!.address}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              placeholder="example: 08123456789"
              name="phoneNumber"
              value={formData!.phoneNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formProvince">
              <Form.Label>Province</Form.Label>
              <Form.Control
                value={formData!.province}
                name="province"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formCity">
              <Form.Label>City</Form.Label>
              {/* <Form.Select defaultValue="Choose...">
                <option>Choose...</option>
                <option>...</option>
              </Form.Select> */}
              <Form.Control
                value={formData!.city}
                name="city"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formDistrict">
              <Form.Label>District</Form.Label>
              <Form.Control
                value={formData!.district}
                name="district"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formVillage">
              <Form.Label>Village</Form.Label>
              <Form.Control
                value={formData!.village}
                name="village"
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="formGroupGender">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender" // Menambahkan name di sini
              value={formData.gender}
              onChange={handleChange}
            >
              <option value="">Select your gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="imageUpload" className="mb-3">
            <Form.Label>Upload Image</Form.Label>
            <Form.Control type="file" />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CreateMember;
