import { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

interface DownloadTableModalProps {
  show: boolean;
  onHide: () => void;
  onDownload: (format: string, selectedData: string[]) => void;
  title?: string;
  isCustomerDetails?: boolean;
}

const DownloadTableModal = ({
  show,
  onHide,
  onDownload,
  title = 'Unduh Tabel',
  isCustomerDetails = false
}: DownloadTableModalProps) => {
  const [format, setFormat] = useState('portrait-pdf');
  const [selectedData, setSelectedData] = useState<string[]>([]);

  const dataOptions = [
    { value: 'info-umum', label: 'Informasi Umum' },
    { value: 'diagram-gigi', label: 'Diagram Gigi' },
    { value: 'form-template', label: 'Form Template' },
    { value: 'booking', label: 'Booking' },
    { value: 'penjualan', label: 'Penjualan' },
    { value: 'peringkat', label: 'Peringkat dan Ulasan' }
  ];

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '12px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (provided: any) => ({
      ...provided,
      fontSize: '12px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: any) => ({
      ...provided,
      fontSize: '12px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: '12px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValue: (provided: any) => ({
      ...provided,
      fontSize: '12px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValueLabel: (provided: any) => ({
      ...provided,
      fontSize: '12px'
    })
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onDownload(format, selectedData);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="px-5 py-4">
          <Modal.Title className="fs-6 fw-bolder">{title}</Modal.Title>
          <div className="ms-auto">
            <Button
              variant="link"
              className="text-danger me-2"
              onClick={onHide}
            >
              Batalkan
            </Button>
            <Button variant="primary" type="submit">
              Unduh
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="px-5 py-4">
          <Form.Group className="mb-3">
            <Form.Label className="p-0 fs-8 mb-2 text-capitalize fw-bold">
              Format Dokumen
            </Form.Label>
            <Form.Select
              value={format}
              onChange={e => setFormat(e.target.value)}
            >
              <option value="portrait-pdf">Portrait PDF</option>
              <option value="landscape-pdf">Landscape PDF</option>
              <option value="excel" disabled>
                Excel
              </option>
              <option value="csv" disabled>
                CSV
              </option>
            </Form.Select>
          </Form.Group>

          {isCustomerDetails && (
            <Form.Group>
              <Form.Label className="p-0 fs-8 mb-2 text-capitalize fw-bold">
                Data yang akan diunduh
              </Form.Label>
              <Select
                isMulti
                options={dataOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                onChange={selected =>
                  setSelectedData(selected.map(item => item.value))
                }
                placeholder="Pilih data yang akan diunduh"
              />
            </Form.Group>
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default DownloadTableModal;
