import { BadgeBg } from 'components/base/Badge';

// Definisi tipe Sale
export interface Sale {
  invoiceNumber: string; // NO.INVOICE
  status: {
    label: string; // Label untuk status (Lunas / Batal)
    type: BadgeBg; // Tipe BadgeBg (misalnya: 'success', 'secondary')
    icon: string; // Ikon untuk status
  };
  location: string; // LOKASI
  total: number; // TOTAL
  paymentMethod: string; // METODE BAYAR
  invoiceDate: string; // TANGGAL INVOICE
}

// Fungsi untuk menghitung total keseluruhan penjualan
export const calculateGrandTotal = (data: Sale[]): number => {
  return data.reduce((sum, record) => sum + record.total, 0);
};

// Contoh data penjualan
export const salesTableData: Sale[] = [
  {
    invoiceNumber: 'INV-001',
    status: { label: 'Lunas', type: 'success', icon: 'check' },
    location: 'Mirza MS Dental Care',
    total: 150000,
    paymentMethod: 'Kartu Kredit',
    invoiceDate: 'Oct 30, 2024'
  },
  {
    invoiceNumber: 'INV-002',
    status: { label: 'Batal', type: 'secondary', icon: 'x' },
    location: 'Mirza MS Dental Care',
    total: 50000,
    paymentMethod: 'Transfer Bank',
    invoiceDate: 'Oct 31, 2024'
  },
  {
    invoiceNumber: 'INV-003',
    status: { label: 'Lunas', type: 'success', icon: 'check' },
    location: 'Mirza MS Dental Care',
    total: 120000,
    paymentMethod: 'Tunai',
    invoiceDate: 'Nov 1, 2024'
  }
];
