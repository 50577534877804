import {
  faCalendarPlus,
  faFileMedical,
  faPlus,
  faTicket
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showCustomAlert } from 'helpers/showCustomAlert';
import React from 'react';
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  Card,
  Table,
  ButtonGroup
} from 'react-bootstrap';

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const NotaPelangganModal: React.FC<ModalProps> = ({ show, onClose }) => {
  const handleCancelClick = () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Invoice akan dihapus',
      deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
      buttonType: 'yes-no',
      onConfirm: () => {
        onClose();
      }
    });
  };

  // Fungsi handler untuk tombol Simpan
  const handleSaveClick = () => {
    showCustomAlert({
      icon: 'question',
      label: 'Invoice akan dibuat',
      deskripsi: 'Apakah Anda yakin data invoice sudah benar?',
      buttonType: 'yes-no',
      onConfirm: () => {
        // Jika user mengonfirmasi simpan, munculkan alert kedua
        showCustomAlert({
          icon: 'success',
          label: 'Sukses!',
          deskripsi: 'Invoice baru berhasil dibuat',
          buttonType: 'ok',
          onConfirm: onClose
        });
      }
    });
  };
  return (
    <Modal closeButton={false} show={show} onHide={onClose} size="xl" centered>
      <Modal.Header className="d-flex justify-content-between p-4">
        <Modal.Title>
          <h4 className="fw-bolder m-0">Buat Invoice</h4>
        </Modal.Title>
        <div className="d-flex gap-3">
          <Button
            className="px-1"
            variant="link"
            style={{ color: 'red' }}
            onClick={handleCancelClick}
          >
            Batalkan
          </Button>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={handleSaveClick}
          >
            Simpan & Buat Baru
          </Button>
          <Button variant="primary" type="submit" onClick={handleSaveClick}>
            Simpan
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#EFF2F6' }}>
        <Form className="px-3 py-1">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="location">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Lokasi
                </Form.Label>
                <Form.Select>
                  <option>Pilih lokasi</option>
                  {/* Other options */}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="date">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Tanggal
                </Form.Label>
                <Form.Control type="date" placeholder="Masukan tanggal" />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="dueDate">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Jatuh Tempo
                </Form.Label>
                <Form.Control type="date" placeholder="Masukan jatuh tempo" />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="customer">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Pelanggan
                </Form.Label>
                <InputGroup>
                  <Form.Control type="search" placeholder="Cari Pelanggan" />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="total">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Total Akhir
                </Form.Label>
                <Form.Select>
                  <option>Belum termasuk pajak</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group controlId="reference">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Referensi
                </Form.Label>
                <Form.Control placeholder="Masukan referensi" />
              </Form.Group>
            </Col>
          </Row>
          <Card className="mb-3">
            <Card.Body>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr
                      className="fs-10 text-uppercase"
                      style={{ backgroundColor: '#E3E6ED' }}
                    >
                      <th>SL No.</th>
                      <th>Nama Item</th>
                      <th>Tipe</th>
                      <th>Pegawai</th>
                      <th style={{ width: '130px' }}>Harga/Unit (Rp)</th>
                      <th style={{ width: '100px' }}>Kuantitas</th>
                      <th>Diskon</th>
                      <th>Pajak</th>
                      <th>Total (Rp)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center fs-9 align-middle">
                      <td>1</td>
                      <td>
                        <Form.Control placeholder="Masukan Item" />
                      </td>
                      <td>
                        <Form.Select>
                          <option>Tipe</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select>
                          <option>Pegawai</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Control />
                      </td>
                      <td>
                        <Form.Control />
                      </td>
                      <td>-</td>
                      <td>
                        <Form.Select>
                          <option>Pajak</option>
                        </Form.Select>
                      </td>
                      <td>0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mb-3">
                <Button variant="outline-primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Tambahkan Item
                </Button>
                <Button variant="outline-primary" className="ms-2">
                  <FontAwesomeIcon icon={faCalendarPlus} className="me-2" />{' '}
                  Booking
                </Button>
                <Button variant="outline-primary" className="ms-2">
                  <FontAwesomeIcon icon={faTicket} className="me-2" /> Tambahkan
                  Bundel
                </Button>
                <Button variant="outline-primary" className="ms-2">
                  <FontAwesomeIcon icon={faFileMedical} className="me-2" />{' '}
                  Histori Pembelian
                </Button>
              </div>

              <Row>
                <Col md={12} xs={12} lg={6}></Col>
                <Col md={12} xs={12} lg={6}>
                  <Table className="fs-9">
                    <tbody>
                      <tr className="align-middle">
                        <td className="text-end"></td>
                        <td className="text-end fw-bold">Sub-total</td>
                        <td className="text-start fw-bold">0.00</td>{' '}
                      </tr>
                      <tr className="align-middle">
                        <td className="text-end"></td>
                        <td className="text-end fw-bold">Tanggal Invoice</td>
                        <td className="text-start">-</td>{' '}
                      </tr>
                      <tr className="align-middle">
                        <td className="text-end fw-bold">Diskon lainnya</td>
                        <td className="text-end">
                          <Form.Select>
                            <option>Pilih diskon lainnya</option>
                          </Form.Select>
                        </td>
                        <td className="text-start fw-bold">0.00</td>{' '}
                      </tr>
                      <tr className="align-middle">
                        <td className="text-end fw-bold">Biaya</td>
                        <td className="text-end d-flex space-content-between gap-5">
                          <Form.Control />
                          <Form.Control />
                        </td>
                        <td className="text-start fw-bold">0.00</td>{' '}
                      </tr>
                      <tr className="align-middle">
                        <td className="text-end fw-bold">Biaya Pajak</td>
                        <td className="text-end">
                          <Form.Select>
                            <option>Pilih skema pajak</option>
                          </Form.Select>
                        </td>
                        <td className="text-start fw-bold">0.00</td>{' '}
                      </tr>
                      <tr className="align-middle">
                        <td className="text-end"></td>
                        <td className="text-end fw-bolder">Total (Rp)</td>
                        <td className="text-start fw-bolder">0.00</td>{' '}
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Card style={{ backgroundColor: '#F5F7FA' }}>
                <Card.Body>
                  <Row className="mb-3">
                    <Col md={12}>
                      <h5>Pembayaran</h5>
                      <Card>
                        <Card.Body className="p-2">
                          <p className="m-0">
                            Pembayaran melalui Transfer Bank :
                          </p>
                          <ul>
                            <li>
                              Bank Mandiri <br /> Atas nama : MIRZA MUTHIA SARI{' '}
                              <br /> Nomor Rekening : 1660004787864
                            </li>
                            <li>
                              Bank Central Asia <br /> Atas nama : MIRZA MUTHIA
                              SARI <br /> Nomor Rekening : 2302836052
                            </li>
                          </ul>
                          <p className="m-0">
                            **Jika Anda melakukan pembayaran transfer bank,
                            harap menyertakan Quote ID ini pada kolom referensi.
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <h5>Persyaratan</h5>
                      <Card>
                        <Card.Body className="p-2">
                          <ul>
                            <li>
                              Mohon periksa kembali transaksi Anda sebelum
                              pembayaran di kasir.
                            </li>
                            <li>
                              Jumlah nominal pembayaran yang harus dibayarkan
                              sesuai dengan yang tercantum pada invoice.
                            </li>
                            <li>
                              Pembayaran dilakukan langsung setelah invoice
                              terbit.
                            </li>
                            <li>
                              Bukti pembayaran mohon untuk dikirimkan ke nomor
                              admin klinik.
                            </li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Form.Group controlId="note">
                    <div className="d-flex justify-content-between align-items-end mb-1">
                      <div>
                        <h5>Catatan</h5>
                      </div>
                      <div>
                        <ButtonGroup>
                          <Button
                            size="sm"
                            variant="primary"
                            style={{ paddingTop: '4px', paddingBottom: '4px' }}
                          >
                            Pribadi
                          </Button>
                          <Button size="sm" variant="outline-primary">
                            Umum
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      defaultValue="Patungan Cicilan 9x ( Cicilan 1 )"
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NotaPelangganModal;
