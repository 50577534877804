// DiagramGigi.tsx
import React, { useState } from 'react';
import { Button, Dropdown, ButtonGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faUpRightAndDownLeftFromCenter
} from '@fortawesome/free-solid-svg-icons';

interface DiagramGigiProps {
  diagramGigiSrc: string;
}

const DiagramGigi: React.FC<DiagramGigiProps> = ({ diagramGigiSrc }) => {
  const [showModal, setShowModal] = useState(false);

  // State to track selected button in each ButtonGroup
  const [firstGroupSelection, setFirstGroupSelection] =
    useState<string>('Selesai');
  const [secondGroupSelection, setSecondGroupSelection] =
    useState<string>('Atas');
  const [thirdGroupSelection, setThirdGroupSelection] =
    useState<string>('Lihat');

  const handleExpandClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="me-1">Diagram Gigi</h3>
        <div className="d-flex">
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="dropdown-caret-none text-decoration-none"
            >
              More action
              <FontAwesomeIcon icon={faChevronDown} className="ms-2" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Button
            variant="outline-primary"
            onClick={handleExpandClick}
            className="ms-2"
          >
            <FontAwesomeIcon
              icon={faUpRightAndDownLeftFromCenter}
              className="me-2"
            />
            Perbesar Tampilan
          </Button>
        </div>
      </div>

      <h3 className="d-flex justify-content-center fw-semibold">
        Tampilan Rahang Atas
      </h3>
      <div className="d-flex justify-content-center">
        <img
          src={diagramGigiSrc}
          alt="Diagram Gigi"
          className="mt-3 img-fluid"
        />
      </div>

      <div className="d-flex justify-content-between mt-3">
        {/* First Button Group */}
        <ButtonGroup>
          <Button
            variant={
              firstGroupSelection === 'Selesai' ? 'primary' : 'outline-primary'
            }
            onClick={() => setFirstGroupSelection('Selesai')}
          >
            Selesai
          </Button>
          <Button
            variant={
              firstGroupSelection === 'Semua' ? 'primary' : 'outline-primary'
            }
            onClick={() => setFirstGroupSelection('Semua')}
          >
            Semua
          </Button>
        </ButtonGroup>

        {/* Second Button Group */}
        <ButtonGroup>
          <Button
            variant={
              secondGroupSelection === 'Atas' ? 'primary' : 'outline-primary'
            }
            onClick={() => setSecondGroupSelection('Atas')}
          >
            Atas
          </Button>
          <Button
            variant={
              secondGroupSelection === 'Semua' ? 'primary' : 'outline-primary'
            }
            onClick={() => setSecondGroupSelection('Semua')}
          >
            Semua
          </Button>
          <Button
            variant={
              secondGroupSelection === 'Bawah' ? 'primary' : 'outline-primary'
            }
            onClick={() => setSecondGroupSelection('Bawah')}
          >
            Bawah
          </Button>
        </ButtonGroup>

        {/* Third Button Group */}
        <ButtonGroup>
          <Button
            variant={
              thirdGroupSelection === 'Lihat' ? 'primary' : 'outline-primary'
            }
            onClick={() => setThirdGroupSelection('Lihat')}
          >
            Lihat
          </Button>
          <Button
            variant={
              thirdGroupSelection === 'Rekam' ? 'primary' : 'outline-primary'
            }
            onClick={() => setThirdGroupSelection('Rekam')}
          >
            Rekam
          </Button>
        </ButtonGroup>
      </div>

      {/* Modal Component */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Diagram Gigi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="d-flex justify-content-center fw-semibold">
            Tampilan Rahang Atas
          </h3>
          <div className="d-flex justify-content-center">
            <img
              src={diagramGigiSrc}
              alt="Diagram Gigi"
              className="mt-3 img-fluid"
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            {/* First Button Group in Modal */}
            <ButtonGroup>
              <Button
                variant={
                  firstGroupSelection === 'Selesai'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setFirstGroupSelection('Selesai')}
              >
                Selesai
              </Button>
              <Button
                variant={
                  firstGroupSelection === 'Semua'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setFirstGroupSelection('Semua')}
              >
                Semua
              </Button>
            </ButtonGroup>

            {/* Second Button Group in Modal */}
            <ButtonGroup>
              <Button
                variant={
                  secondGroupSelection === 'Atas'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setSecondGroupSelection('Atas')}
              >
                Atas
              </Button>
              <Button
                variant={
                  secondGroupSelection === 'Semua'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setSecondGroupSelection('Semua')}
              >
                Semua
              </Button>
              <Button
                variant={
                  secondGroupSelection === 'Bawah'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setSecondGroupSelection('Bawah')}
              >
                Bawah
              </Button>
            </ButtonGroup>

            {/* Third Button Group in Modal */}
            <ButtonGroup>
              <Button
                variant={
                  thirdGroupSelection === 'Lihat'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setThirdGroupSelection('Lihat')}
              >
                Lihat
              </Button>
              <Button
                variant={
                  thirdGroupSelection === 'Rekam'
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() => setThirdGroupSelection('Rekam')}
              >
                Rekam
              </Button>
            </ButtonGroup>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DiagramGigi;
