import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'beranda',
        icon: 'pie-chart',
        path: '/',
        pathName: 'dashboard',
        active: true
      },
      {
        name: 'Kalender',
        icon: 'calendar',
        path: 'apps/calendar',
        pathName: 'app-calendar',
        active: true
      },
      {
        name: 'Pelanggan',
        icon: 'smile',
        active: true,
        new: true,
        pages: [
          {
            name: 'dashboard',
            path: '/apps/e-commerce/admin/customers/dashboard',
            pathName: 'e-commerce-customers-dashboard',
            active: true
          },
          {
            name: 'daftar Pelanggan',
            path: '/apps/e-commerce/admin/customers',
            pathName: 'e-commerce-customers',
            active: true
          },
          {
            name: 'form templat',
            path: '/apps/e-commerce/admin/customer/templat',
            pathName: 'e-commerce-customers-templat',
            active: true
          },
          {
            name: 'pengaturan',
            path: '/apps/e-commerce/admin/customer/pengaturan',
            pathName: 'e-commerce-customers-pengaturan',
            active: true
          }
        ]
      },
      {
        name: 'Layanan',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'dashboard',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Layanan',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'kategori',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'kebijakan',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'templat',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pengaturan',
            path: '-',
            pathName: '-',
            active: true
          }
        ]
      },
      {
        name: 'Pegawai',
        icon: 'users',
        active: true,
        pages: [
          {
            name: 'dashboard',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Pegawai',
            path: '/pages/members',
            pathName: 'members-page',
            active: true
          },
          {
            name: 'Kontrol Akses',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Peran Keamanan',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'templat',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pengaturan',
            path: '-',
            pathName: '-',
            active: true
          }
        ]
      },
      {
        name: 'Perpesanan',
        icon: 'mail',
        path: '-',
        pathName: '-',
        active: true
      },
      {
        name: 'Percakapan',
        icon: 'message-circle',
        path: '-',
        pathName: '-',
        active: true
      },
      {
        name: 'produk',
        icon: 'package',
        active: true,
        pages: [
          {
            name: 'dashboard',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Produk',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'bundel',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'kategori',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'restock',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'kurir',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pemasok',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'templat',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pengaturan',
            path: '-',
            pathName: '-',
            active: true
          }
        ]
      },
      {
        name: 'promosi',
        icon: 'tag',
        active: true,
        pages: [
          {
            name: 'dashboard',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Promosi',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Paket',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'kebijakan',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'mitra',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'templat',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pengaturan',
            path: '-',
            pathName: '-',
            active: true
          }
        ]
      },
      {
        name: 'lokasi',
        icon: 'map-pin',
        active: true,
        pages: [
          {
            name: 'dashboard',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Lokasi',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'fasilitas',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Jadwal Buka',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'templat',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pengaturan',
            path: '-',
            pathName: '-',
            active: true
          }
        ]
      },
      {
        name: 'keuangan',
        icon: 'trending-up',
        active: true,
        pages: [
          {
            name: 'dashboard',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Penjualan',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'Daftar Pengeluaran',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pajak',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'templat',
            path: '-',
            pathName: '-',
            active: true
          },
          {
            name: 'pengaturan',
            path: '-',
            pathName: '-',
            active: true
          }
        ]
      },
      {
        name: 'Profil',
        icon: 'user',
        path: '-',
        pathName: '-',
        active: true
      }
    ]
  }
];
