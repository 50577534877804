import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const defaultBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Page 1',
    url: '#!'
  },
  {
    label: 'Page 2',
    url: '#!'
  },
  {
    label: 'Default',
    active: true
  }
];

export const ecomBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Fashion',
    url: '#!'
  },
  {
    label: 'Womens Fashion',
    url: '#!'
  },
  {
    label: 'Footwear',
    url: '#!'
  },
  {
    label: 'Hills',
    active: true
  }
];

export const daftarPelangganBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Daftar Pelanggan',
    active: true
  }
];

export const detailPelangganBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Daftar Pelanggan',
    url: '/apps/e-commerce/admin/customers'
  },
  {
    label: 'Detail Pelanggan',
    active: true
  }
];

export const formTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Form Templat',
    active: true
  }
];

export const detailFormTemplatBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pelanggan',
    url: '#!'
  },
  {
    label: 'Form Templat',
    url: '/apps/e-commerce/admin/customer/templat'
  },
  {
    label: 'Form Templat',
    active: true
  }
];
