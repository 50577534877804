import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';

interface DeleteCustomerModalProps {
  show: boolean;
  onHide: () => void;
  selectedCustomers: { name: string }[];
  onDelete: (reason: string, notes: string) => void;
}

const DeleteCustomerModal = ({
  show,
  onHide,
  selectedCustomers,
  onDelete
}: DeleteCustomerModalProps) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [notes, setNotes] = useState('');

  const handleDelete = () => {
    if (!reason || reason === '') {
      setError('Alasan penghapusan harus dipilih');
      return;
    }

    setError('');
    onDelete(reason, notes);
  };

  const handleHide = () => {
    setReason('');
    setError('');
    setNotes('');
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header className="px-5 py-4">
        <Modal.Title>Hapus Pelanggan</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 py-4">
        <Form.Group className="mb-3">
          <Form.Label className="p-0">
            Alasan Penghapusan <span className="text-danger">*</span>
          </Form.Label>
          <Form.Select
            value={reason}
            onChange={e => {
              setReason(e.target.value);
              setError('');
            }}
            isInvalid={!!error}
          >
            <option value="">Masukan alasan penghapusan</option>
            <option value="Data tidak valid">Data tidak valid</option>
            <option value="Customer Request">Permintaan pelanggan</option>
            <option value="Others">Lainnya</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label className="p-0">Catatan</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Masukan catatan"
            rows={3}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </Form.Group>
        <div className="mt-3">
          <h6>Pelanggan yang Akan Dihapus</h6>
          <ul className="ps-3">
            {selectedCustomers.map((customer, index) => (
              <li key={index}>{customer.name}</li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="px-5 py-3">
        <Button variant="link" onClick={handleHide}>
          Batalkan
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Hapus
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCustomerModal;
