import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { CustomerTemplate } from 'api/response/CustomerTemplateResponse';

export const customersTemplateTablecolumns: ColumnDef<CustomerTemplate>[] = [
  {
    accessorKey: 'templateName',
    header: 'Nama Template',
    cell: ({ row: { original } }) => {
      const { templateName, id } = original;
      return (
        <Link
          to={`/apps/e-commerce/admin/customer/templat-details/${id}`}
          className="d-flex align-items-center text-body-emphasis"
        >
          <p className="mb-0 ms-3 fw-bold">{templateName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-5 ps-0' },
      cellProps: { className: 'py-0 pe-5 ps-0 white-space-nowrap' }
    }
  },
  {
    accessorKey: 'createdBy',
    header: 'Dibuat oleh',
    cell: ({ row: { original } }) => {
      return <span className="fw-semibold">{original.createdBy}</span>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-5 ps-1' },
      cellProps: { className: 'pe-5 ps-1' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => {
      return (
        <span
          className={`badge ${original.status ? 'bg-success' : 'bg-secondary'}`}
        >
          {original.status ? 'Active' : 'Inactive'}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'text-center px-1' },
      cellProps: {
        className: 'fw-semibold text-body-highlight text-center px-1'
      }
    }
  },
  {
    accessorKey: 'formType',
    header: 'Tipe Form',
    cell: ({ row: { original } }) => original.formType,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'text-start ps-3 pe-1'
      },
      cellProps: {
        className: 'fw-bold text-body-emphasis text-start ps-3 pe-1'
      }
    }
  },
  {
    accessorKey: 'totalCustomer',
    header: 'Total Pelanggan',
    cell: ({ row: { original } }) => original.totalCustomer,
    meta: {
      headerProps: { style: { width: '15%' }, className: 'ps-7' },
      cellProps: { className: 'ps-7 text-body-highlight white-space-nowrap' }
    }
  },
  {
    accessorKey: 'totalMerchant',
    header: 'Total Pedagang',
    cell: ({ row: { original } }) => original.totalMerchant,
    meta: {
      headerProps: { style: { width: '15%' }, className: 'ps-7' },
      cellProps: { className: 'ps-7 text-body-highlight white-space-nowrap' }
    }
  },
  {
    accessorKey: 'modifiedDate',
    header: 'Terakhir Diperbaharui',
    cell: ({ row: { original } }) => {
      return new Date(original.modifiedDate).toLocaleString('id-ID', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end px-1' },
      cellProps: { className: 'text-body-tertiary text-end px-1' }
    }
  },
  {
    accessorKey: 'createdDate',
    header: 'Dibuat Tanggal',
    cell: ({ row: { original } }) => {
      return new Date(original.createdDate).toLocaleString('id-ID', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: 150 },
        className: 'text-end'
      },
      cellProps: { className: 'text-body-tertiary text-end pe-0' }
    }
  }
];

interface CustomersTemplateTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalPages: number;
}

const CustomersTemplateTable = ({
  onPageChange,
  currentPage,
  totalPages
}: CustomersTemplateTableProps) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter
        pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default CustomersTemplateTable;
