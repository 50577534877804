import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import {
  useGetCustomerByIdQuery,
  useUpdateEmailMutation,
  useUpdateLocationMutation
} from 'api/customerApi';
import { useParams } from 'react-router-dom';
import { useGetProvincesQuery, useGetRegenciesQuery } from 'api/locationApi';
import { showCustomAlert } from 'helpers/showCustomAlert';

const AlamatKontakCard = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, isLoading, error } = useGetCustomerByIdQuery(id!);
  const [updateEmail, { isLoading: isSavingEmail }] = useUpdateEmailMutation();
  const [updateLocation, { isLoading: isSavingLocation }] =
    useUpdateLocationMutation(); // Mutation untuk update lokasi

  // State untuk menyimpan data form
  const [formData, setFormData] = useState({
    alamat: '',
    kota: '',
    kotaName: '',
    provinsi: '',
    provinsiName: '',
    negara: '',
    kodePos: '',
    jenisAlamat: '',
    phone: '',
    jenisPhone: '',
    terhubungWA: false,
    email: '',
    jenisEmail: '',
    district: '',
    districtName: '',
    village: '',
    villageName: ''
  });

  // State untuk mode editing dan penyimpanan ID
  const [isEditing, setIsEditing] = useState(false);
  const [emailId, setEmailId] = useState<string | null>(null); // Menyimpan id email
  const [addressId, setAddressId] = useState<string | null>(null); // Menyimpan id alamat

  // Tambahkan state untuk error
  const [errors, setErrors] = useState({
    alamat: '',
    kota: '',
    provinsi: '',
    negara: '',
    jenisAlamat: '',
    phone: '',
    jenisPhone: ''
  });

  // Ambil data provinsi
  const { data: provincesResponse, isLoading: isLoadingProvinces } =
    useGetProvincesQuery();

  // Ambil data kota berdasarkan provinsi yang dipilih
  const { data: regenciesResponse, isLoading: isLoadingRegencies } =
    useGetRegenciesQuery(formData.provinsi || '', {
      skip: !formData.provinsi
    });

  // Ekstrak data provinces dan regencies
  const provinces = provincesResponse?.data || [];
  const regencies = regenciesResponse?.data || [];

  // Untuk debug
  console.log('Provinces Response:', provincesResponse);
  console.log('Provinces Data:', provinces);

  // Hook untuk mendapatkan data customer berdasarkan id
  useEffect(() => {
    if (response?.data?.addressAndContact) {
      const address = response.data.addressAndContact.addresses[0];
      const email = response.data.addressAndContact.emails[0];
      const contact = response.data.addressAndContact.contacts[0];

      setEmailId(email?.id || null);
      setAddressId(address?.id || null);

      setFormData({
        alamat: address?.street || '-',
        provinsi: address?.province?.id || '',
        provinsiName: address?.province?.name || '-',
        kota: address?.regency?.id || '',
        kotaName: address?.regency?.name || '-',
        negara: address?.country || '-',
        kodePos: address?.postalCode || '-',
        jenisAlamat: address?.addressType || '-',
        phone: contact?.number || '-',
        jenisPhone: contact?.usageType || '-',
        terhubungWA: contact?.isWhatsapp || false,
        email: email?.email || '-',
        jenisEmail: email?.usageType || '-',
        district: address?.district?.id || '',
        districtName: address?.district?.name || '-',
        village: address?.village?.id || '',
        villageName: address?.village?.value || '-'
      });
    } else {
      console.warn('Data addressAndContact tidak ditemukan');
    }
  }, [response]);

  // Cek apakah data sudah ada, jika tidak tampilkan pesan error
  if (isLoading) return <div>Loading...</div>;
  if (error || !response?.data?.addressAndContact)
    return <div>No customer data found</div>;

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'provinsi') {
      // Reset kota ketika provinsi berubah
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        provinsiName:
          provinces?.find((p: { id: string }) => p.id === value)?.name || '',
        kota: '', // Reset kota
        kotaName: '' // Reset nama kota
      }));
    } else if (name === 'kota') {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        kotaName:
          regencies?.find((r: { id: string }) => r.id === value)?.name || ''
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleCancel = () => {
    // Cek apakah ada perubahan data dengan membandingkan data saat ini dengan data awal
    const originalData = response?.data?.addressAndContact;
    const address = originalData?.addresses[0];
    const email = originalData?.emails[0];
    const contact = originalData?.contacts[0];

    const hasChanges =
      originalData &&
      (formData.alamat !== (address?.street || '-') ||
        formData.provinsi !== (address?.province?.id || '') ||
        formData.kota !== (address?.regency?.id || '') ||
        formData.negara !== (address?.country || '-') ||
        formData.kodePos !== (address?.postalCode || '-') ||
        formData.jenisAlamat !== (address?.addressType || '-') ||
        formData.phone !== (contact?.number || '-') ||
        formData.jenisPhone !== (contact?.usageType || '-') ||
        formData.terhubungWA !== (contact?.isWhatsapp || false) ||
        formData.email !== (email?.email || '-') ||
        formData.jenisEmail !== (email?.usageType || '-'));

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan data alamat dan kontak? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        // Reset form ke data awal
        if (response?.data?.addressAndContact) {
          const address = response.data.addressAndContact.addresses[0];
          const email = response.data.addressAndContact.emails[0];
          const contact = response.data.addressAndContact.contacts[0];

          setFormData({
            alamat: address?.street || '-',
            provinsi: address?.province?.id || '',
            provinsiName: address?.province?.name || '-',
            kota: address?.regency?.id || '',
            kotaName: address?.regency?.name || '-',
            negara: address?.country || '-',
            kodePos: address?.postalCode || '-',
            jenisAlamat: address?.addressType || '-',
            phone: contact?.number || '-',
            jenisPhone: contact?.usageType || '-',
            terhubungWA: contact?.isWhatsapp || false,
            email: email?.email || '-',
            jenisEmail: email?.usageType || '-',
            district: address?.district?.id || '',
            districtName: address?.district?.name || '-',
            village: address?.village?.id || '',
            villageName: address?.village?.value || '-'
          });
        }
      }
    });
  };

  const handleSave = async () => {
    // Validasi seperti sebelumnya
    const newErrors = {
      alamat: '',
      kota: '',
      provinsi: '',
      negara: '',
      jenisAlamat: '',
      phone: '',
      jenisPhone: ''
    };

    // Validasi field required
    if (!formData.alamat.trim()) newErrors.alamat = 'Alamat harus diisi';
    if (!formData.kota) newErrors.kota = 'Kota harus dipilih';
    if (!formData.provinsi) newErrors.provinsi = 'Provinsi harus dipilih';
    if (!formData.negara) newErrors.negara = 'Negara harus dipilih';
    if (!formData.jenisAlamat)
      newErrors.jenisAlamat = 'Jenis alamat harus dipilih';
    if (!formData.phone.trim()) newErrors.phone = 'Nomor telepon harus diisi';
    if (!formData.jenisPhone)
      newErrors.jenisPhone = 'Jenis penggunaan harus dipilih';

    setErrors(newErrors);

    if (Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi:
        'Anda yakin ingin menyimpan perubahan data alamat dan kontak ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          if (!emailId) {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal',
              deskripsi: 'Email ID tidak ditemukan',
              buttonType: 'ok'
            });
            return;
          }

          const emailData = {
            id: emailId,
            email: formData.email,
            usageType: formData.jenisEmail
          };

          await updateEmail({ id: emailId, data: emailData }).unwrap();

          if (!addressId) {
            showCustomAlert({
              icon: 'error',
              label: 'Gagal',
              deskripsi: 'Alamat ID tidak ditemukan',
              buttonType: 'ok'
            });
            return;
          }

          const locationData = {
            street: formData.alamat,
            province: formData.provinsi.toString(),
            regency: formData.kota.toString(),
            district: formData.district?.toString() || '',
            village: formData.village?.toString() || '',
            country: formData.negara,
            postalCode: formData.kodePos,
            addressType: formData.jenisAlamat
          };

          await updateLocation({ id: addressId, data: locationData }).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data alamat dan kontak berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: error?.data?.message || 'Terjadi kesalahan pada server.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <Card className="w-100">
      <Card.Body>
        {/* Judul dan Tombol Edit */}
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>Alamat & Kontak</h3>
            {!isEditing && (
              <Button variant="ghost" size="sm" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {/* Mode tampilan (display) */}
        {!isEditing && (
          <>
            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Alamat</span>
                <span className="fw-normal">
                  {formData.alamat || '-'}
                  <br />
                  Kecamatan {formData.districtName || '-'}
                  <br />
                  {formData.kotaName || '-'}
                  <br />
                  {formData.provinsiName || '-'}
                  <br />
                  {formData.negara || '-'}
                  <br />
                  {formData.kodePos || '-'}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Email</span>
                <span className="fw-normal">
                  {formData.email ? (
                    <a href={`mailto:${formData.email}`}>{formData.email}</a>
                  ) : (
                    '-'
                  )}
                </span>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12} className="d-flex flex-column pe-3">
                <span className="fw-bold text-muted">Phone</span>
                <span className="fw-normal">
                  {formData.phone ? (
                    <a
                      href={`https://wa.me/${formData.phone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formData.phone}
                    </a>
                  ) : (
                    '-'
                  )}
                </span>
              </Col>
            </Row>

            {/* Batas */}
          </>
        )}

        {/* Mode edit */}

        {isEditing && (
          <>
            <h2 className="mb-2 mt-0 fs-7 text-capitalize">Alamat</h2>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Alamat <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="alamat"
                    type="text"
                    placeholder="Alamat"
                    value={formData.alamat}
                    onChange={handleInputChange}
                    isInvalid={!!errors.alamat}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.alamat}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Provinsi <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="provinsi"
                    value={formData.provinsi}
                    onChange={handleInputChange}
                    isInvalid={!!errors.provinsi}
                    disabled={isLoadingProvinces}
                  >
                    <option value="">Pilih Provinsi</option>
                    {Array.isArray(provinces) &&
                      provinces.map(province => (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.provinsi}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Kota <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="kota"
                    value={formData.kota}
                    onChange={handleInputChange}
                    isInvalid={!!errors.kota}
                    disabled={!formData.provinsi || isLoadingRegencies}
                  >
                    <option value="">Pilih Kota</option>
                    {Array.isArray(regencies) &&
                      regencies.map(regency => (
                        <option key={regency.id} value={regency.id}>
                          {regency.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.kota}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Negara <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="negara"
                    value={formData.negara}
                    onChange={handleInputChange}
                    isInvalid={!!errors.negara}
                  >
                    <option value="">Pilih Negara</option>
                    <option value="indonesia">Indonesia</option>
                    <option>Malaysia</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.negara}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Kode Pos
                  </Form.Label>
                  <Form.Control
                    name="kodePos"
                    type="text"
                    placeholder="Kode Pos"
                    value={formData.kodePos}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Jenis Alamat <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="jenisAlamat"
                    value={formData.jenisAlamat}
                    onChange={handleInputChange}
                    isInvalid={!!errors.jenisAlamat}
                  >
                    <option value="">Pilih Jenis Alamat</option>
                    <option>Rumah</option>
                    <option>Kos</option>
                    <option>Apartemen</option>
                    <option>Lainnya</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.jenisAlamat}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Button variant="outline-primary" className="mt-2 w-100">
                  + Tambah Alamat
                </Button>
              </Col>
            </Row>

            <h2 className="mb-2 mt-3 fs-7 text-capitalize">Telepon Pribadi</h2>
            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Nomor Telepon <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="phone"
                    type="text"
                    placeholder="Nomor telepon"
                    value={formData.phone}
                    onChange={handleInputChange}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={8}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Jenis Penggunaan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="jenisPhone"
                    value={formData.jenisPhone}
                    onChange={handleInputChange}
                    isInvalid={!!errors.jenisPhone}
                  >
                    <option value="">Pilih Jenis Penggunaan</option>
                    <option>Telepon utama</option>
                    <option>Telepon kantor</option>
                    <option>Lainnya</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.jenisPhone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Terhubung WhatsApp
                  </Form.Label>
                  <div className="d-flex gap-2 pt-1">
                    <Form.Check
                      type="radio"
                      name="terhubungWA"
                      label="Ya"
                      value="Ya"
                      checked={formData.terhubungWA === true}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      type="radio"
                      name="terhubungWA"
                      label="Tidak"
                      value="Tidak"
                      checked={formData.terhubungWA === false}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="outline-primary" className="mt-2 w-100">
              + Tambah Alamat
            </Button>

            <h2 className="mb-2 mt-5 fs-7 text-capitalize">Email</h2>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Alamat Email
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} md={12}>
                <Form.Group>
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Jenis Penggunaan
                  </Form.Label>
                  <Form.Select
                    name="jenisEmail"
                    value={formData.jenisEmail}
                    onChange={handleInputChange}
                  >
                    <option>Pilih Jenis Penggunaan</option>
                    <option>Individual</option>
                    <option>Corporate/Business</option>
                    <option>Group</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {/* Batas */}

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button
                onClick={handleSave}
                disabled={isSavingEmail || isSavingLocation}
              >
                {isSavingEmail || isSavingLocation ? 'Menyimpan...' : 'Simpan'}
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default AlamatKontakCard;
