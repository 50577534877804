import { configureStore } from '@reduxjs/toolkit';
import { customerApi } from '../api/customerApi';
import { locationApi } from '../api/locationApi';
import { customerTemplateApi } from '../api/customerTemplateApi';
import { authApi } from 'api/authApi';

export const store = configureStore({
  reducer: {
    [customerApi.reducerPath]: customerApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [customerTemplateApi.reducerPath]: customerTemplateApi.reducer,
    [authApi.reducerPath]: authApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(customerApi.middleware)
      .concat(locationApi.middleware)
      .concat(customerTemplateApi.middleware)
      .concat(authApi.middleware)
});
