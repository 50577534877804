import { BadgeBg } from 'components/base/Badge';

// Misalkan definisi tipe Booking
export interface Booking {
  location: string; // Lokasi
  status: {
    label: string; // Label untuk status
    type: BadgeBg; // Tipe BadgeBg (misalnya: 'success', 'info', 'warning', 'secondary')
    icon: string; // Ikon untuk status
  };
  employee: string; // Nama pegawai
  service: string; // Nama layanan
  booking_time: string; // Waktu booking
}

// Contoh data booking
export const bookingsTableData: Booking[] = [
  {
    location: 'Mirza MS Dental Care',
    status: {
      label: 'On Going',
      type: 'primary',
      icon: 'alert-octagon'
    },
    employee: 'drg. Mirza Muthia Sari',
    service: 'Cabut Gigi',
    booking_time: 'Thursday, 24 October, 2024 11:35 AM'
  },
  {
    location: 'Mirza MS Dental Care',
    status: {
      label: 'Selesai',
      type: 'success',
      icon: 'check'
    },
    employee: 'drg. Mirza Muthia Sari',
    service: 'Pendaftaran',
    booking_time: 'Thursday, 24 October, 2024 11:15 AM'
  },
  {
    location: 'Mirza MS Dental Care',
    status: {
      label: 'Batal',
      type: 'secondary',
      icon: 'x'
    },
    employee: 'drg. Mirza Muthia Sari',
    service: 'Pendaftaran',
    booking_time: 'Thursday, 24 October, 2024 11:00 AM'
  }
];
