import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CustomerData, CustomerResponse } from './response/CustomerResponse';
import { BaseResponse } from './response/BaseResponse';
import { FormData } from './request/CustomerCreateReq';

// Tambahkan interface untuk parameter query
interface CustomerQueryParams {
  page: number;
  limit: number;
  orderBy: string;
  direction: 'ASC' | 'DESC';
  gender?: string[];
  location?: string[];
  customerType?: string[];
  startAt?: string;
  endAt?: string;
  name?: string;
}

// Tambahkan interface untuk request delete customer
interface DeleteCustomerRequest {
  notes: string;
  deletionReason: string;
  data: number[];
}

interface CustomerCreateResponse extends BaseResponse {
  data: {
    id: number;
  };
}

export const customerApi = createApi({
  reducerPath: 'customer',
  tagTypes: ['Customer'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: builder => ({
    getCustomer: builder.query<CustomerData, CustomerQueryParams>({
      query: params => {
        const queryParams = new URLSearchParams();

        // Parameter dasar
        queryParams.append('page', params.page.toString());
        queryParams.append('limit', params.limit.toString());
        queryParams.append('orderBy', params.orderBy);
        queryParams.append('direction', params.direction);

        // Filter tambahan
        if (params.gender?.length) {
          params.gender.forEach(g => queryParams.append('gender', g));
        }
        if (params.location?.length) {
          params.location.forEach(l =>
            queryParams.append('branch', l.toLowerCase())
          );
        }
        if (params.customerType?.length) {
          params.customerType.forEach(t =>
            queryParams.append('customerType', t)
          );
        }
        if (params.startAt) {
          queryParams.append('startAt', params.startAt);
        }
        if (params.endAt) {
          queryParams.append('endAt', params.endAt);
        }
        if (params.name) {
          queryParams.append('name', params.name);
        }

        const url = `api/v1/customer?${queryParams.toString()}`;
        console.log('Request URL:', url);
        return url;
      },
      providesTags: ['Customer']
    }),
    addCustomer: builder.mutation<CustomerCreateResponse, FormData>({
      query: newData => ({
        url: 'api/v1/customer',
        method: 'POST',
        body: newData
      })
    }),
    getCustomerById: builder.query<
      BaseResponse & { data: CustomerResponse },
      string
    >({
      query: id => `api/v1/customer/detail/${id}`,
      transformResponse: (
        response: BaseResponse & { data: CustomerResponse }
      ) => {
        return response;
      }
    }),
    editCustomer: builder.mutation({
      query: data => ({
        url: '/api/v1/customer/detail/edit',
        method: 'PUT',
        body: data // Semua data dikirimkan melalui body
      })
    }),
    updateLocation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/location/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    updateEmail: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/email/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    updateEmergencyContact: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/emergency-contact/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    addCustomerNote: builder.mutation<
      BaseResponse & {
        data: {
          id: string;
          content: string;
          type: string;
          createdDate: string;
        };
      },
      {
        customerId: number;
        content: string;
        noteType: string;
      }
    >({
      query: newNote => ({
        url: '/api/v1/customernote/create',
        method: 'POST',
        body: newNote
      })
    }),
    deleteCustomer: builder.mutation<BaseResponse, DeleteCustomerRequest>({
      query: deleteRequest => ({
        url: 'api/v1/customer',
        method: 'DELETE',
        body: deleteRequest
      }),
      invalidatesTags: ['Customer']
    }),
    editCustomerNote: builder.mutation<
      BaseResponse & {
        data: {
          id: string;
          content: string;
          type: string;
          createdDate: string;
        };
      },
      {
        id: number;
        content: string;
        noteType: string;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/api/v1/customernote/edit/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteCustomerNote: builder.mutation<BaseResponse, number>({
      query: id => ({
        url: `/api/v1/customernote/${id}`,
        method: 'DELETE'
      })
    }),
    getUniqueBranches: builder.query<string[], void>({
      query: () => 'api/v1/customer?page=1&limit=1000',
      transformResponse: (response: CustomerData) => {
        if (!response.data) return [];

        const branches = response.data
          .map(customer => customer.branch)
          .filter(
            (branch): branch is string =>
              Boolean(branch) && typeof branch === 'string'
          )
          .filter(
            (branch, index, self) =>
              self.findIndex(b => b.toLowerCase() === branch.toLowerCase()) ===
              index
          )
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        console.log('Transformed branches:', branches);
        return branches;
      }
    })
  })
});

export const {
  useGetCustomerQuery,
  useAddCustomerMutation,
  useGetCustomerByIdQuery,
  useEditCustomerMutation,
  useUpdateLocationMutation,
  useUpdateEmailMutation,
  useUpdateEmergencyContactMutation,
  useAddCustomerNoteMutation,
  useDeleteCustomerMutation,
  useEditCustomerNoteMutation,
  useDeleteCustomerNoteMutation,
  useGetUniqueBranchesQuery
} = customerApi;
