import team32 from 'assets/img/team/32.webp';
import team35 from 'assets/img/team/35.webp';
import team57 from 'assets/img/team/57.webp';
import team58 from 'assets/img/team/58.webp';
import team59 from 'assets/img/team/59.webp';
import { BadgeBg } from 'components/base/Badge';

export type Customer = {
  avatar?: string;
  name: string;
  email: string;
  phoneNumber?: string; // NO. TELEPON
  customerGroup?: string; // KELOMPOK PELANGGAN
  joinDate?: string; // TANGGAL BERGABUNG
  city: string;
  totalOrders: number; // AKUMULASI PEMESANAN
  totalSpent: number;
  lastSeen: string;
  lastOrder: string; // PESANAN TERAKHIR
  placeholder?: boolean;
};
export type CustomerReview = {
  product: string;
  rating: number;
  review: string;
  status: {
    title: string;
    badgeBg: BadgeBg;
    icon: string;
  };
  time: string;
};

export const customers: Customer[] = [
  {
    avatar: team32,
    name: 'Carry Anna',
    email: 'annac34@gmail.com',
    phoneNumber: '+62 812-3456-7890',
    customerGroup: 'New',
    joinDate: 'Jan 12, 2022',
    city: 'Jakarta',
    totalOrders: 89500000,
    totalSpent: 23987000,
    lastSeen: '34 min ago',
    lastOrder: 'Dec 12, 12:56 PM'
  },
  {
    name: 'Milind Mikuja',
    placeholder: true,
    email: 'mimiku@yahoo.com',
    phoneNumber: '+62 813-4567-8910',
    customerGroup: 'Belum teridentifikasi',
    joinDate: 'Feb 8, 2021',
    city: 'Bandung',
    totalOrders: 76000000,
    totalSpent: 21567000,
    lastSeen: '6 hours ago',
    lastOrder: 'Dec 9, 2:28 PM'
  },
  {
    avatar: team35,
    name: 'Stanly Drinkwater',
    email: 'stnlwasser@hotmail.com',
    phoneNumber: '+62 852-9876-5432',
    customerGroup: 'New',
    joinDate: 'Mar 15, 2023',
    city: 'Surabaya',
    totalOrders: 69000000,
    totalSpent: 19872000,
    lastSeen: '43 min ago',
    lastOrder: 'Dec 4, 12:56 PM'
  },
  {
    avatar: team57,
    name: 'Fadillah Mahendra',
    email: 'fadillah.mahen@gmail.com',
    phoneNumber: '+62 878-5678-1234',
    customerGroup: 'Belum teridentifikasi',
    joinDate: 'Jul 20, 2022',
    city: 'Yogyakarta',
    totalOrders: 105000000,
    totalSpent: 24990000,
    lastSeen: '1 day ago',
    lastOrder: 'Dec 3, 10:40 AM'
  },
  {
    avatar: team58,
    name: 'Nadia Wijaya',
    email: 'nadiawijaya@outlook.com',
    phoneNumber: '+62 822-3344-5566',
    customerGroup: 'New',
    joinDate: 'May 30, 2022',
    city: 'Medan',
    totalOrders: 50000000,
    totalSpent: 17980000,
    lastSeen: '3 days ago',
    lastOrder: 'Dec 2, 8:20 PM'
  },
  {
    avatar: team59,
    name: 'Hana Salsabila',
    email: 'hana.salsa@ymail.com',
    phoneNumber: '+62 819-3344-7788',
    customerGroup: 'Belum teridentifikasi',
    joinDate: 'Sep 15, 2023',
    city: 'Semarang',
    totalOrders: 42000000,
    totalSpent: 13990000,
    lastSeen: '5 hours ago',
    lastOrder: 'Dec 11, 9:30 AM'
  },
  {
    name: 'Milind Mikuja',
    placeholder: true,
    email: 'mimiku@yahoo.com',
    phoneNumber: '+62 813-4567-8910',
    customerGroup: 'Belum teridentifikasi',
    joinDate: 'Feb 8, 2021',
    city: 'Bandung',
    totalOrders: 76000000,
    totalSpent: 21567000,
    lastSeen: '6 hours ago',
    lastOrder: 'Dec 9, 2:28 PM'
  },
  {
    avatar: team35,
    name: 'Stanly Drinkwater',
    email: 'stnlwasser@hotmail.com',
    phoneNumber: '+62 852-9876-5432',
    customerGroup: 'New',
    joinDate: 'Mar 15, 2023',
    city: 'Surabaya',
    totalOrders: 69000000,
    totalSpent: 19872000,
    lastSeen: '43 min ago',
    lastOrder: 'Dec 4, 12:56 PM'
  },
  {
    avatar: team57,
    name: 'Fadillah Mahendra',
    email: 'fadillah.mahen@gmail.com',
    phoneNumber: '+62 878-5678-1234',
    customerGroup: 'Belum teridentifikasi',
    joinDate: 'Jul 20, 2022',
    city: 'Yogyakarta',
    totalOrders: 105000000,
    totalSpent: 24990000,
    lastSeen: '1 day ago',
    lastOrder: 'Dec 3, 10:40 AM'
  },
  {
    avatar: team58,
    name: 'Nadia Wijaya',
    email: 'nadiawijaya@outlook.com',
    phoneNumber: '+62 822-3344-5566',
    customerGroup: 'New',
    joinDate: 'May 30, 2022',
    city: 'Medan',
    totalOrders: 50000000,
    totalSpent: 17980000,
    lastSeen: '3 days ago',
    lastOrder: 'Dec 2, 8:20 PM'
  },
  {
    avatar: team59,
    name: 'Hana Salsabila',
    email: 'hana.salsa@ymail.com',
    phoneNumber: '+62 819-3344-7788',
    customerGroup: 'Belum teridentifikasi',
    joinDate: 'Sep 15, 2023',
    city: 'Semarang',
    totalOrders: 42000000,
    totalSpent: 13990000,
    lastSeen: '5 hours ago',
    lastOrder: 'Dec 11, 9:30 AM'
  }
];

export const customerNotes = [
  {
    note: 'Gave us a nice feedback',
    date: '12 Nov, 2020',
    type: 'pribadi'
  },
  {
    note: 'Customer added product to cart and then forgot to checkout. Later knocked the customer support to ask about update on shipping. Later, settled on “One day Shipping” though “Free delivery” was preferred. Overall good behavior.',
    date: '23 Dec, 2019',
    type: 'umum'
  }
];

export const customerRatingsTableData: CustomerReview[] = [
  {
    product: 'Cabut Gigi',
    rating: 4,
    review:
      'Pelayanan cabut gigi di klinik ini sangat profesional dan minim rasa sakit. Dokter serta asisten sangat sabar dalam menjelaskan setiap proses.',
    status: {
      title: 'Success',
      badgeBg: 'success',
      icon: 'check'
    },
    time: 'Just Now'
  },
  {
    product: 'Scalling, Konsultasi',
    rating: 4,
    review:
      'Proses scalling di sini sangat nyaman dan hasilnya memuaskan. Setelah tindakan, gigi terasa lebih bersih dan.... See more',
    status: {
      title: 'Success',
      badgeBg: 'success',
      icon: 'check'
    },
    time: 'Oct 30, 2024'
  },
  {
    product: 'Vitamin C1000',
    rating: 4.5,
    review:
      'Vitamin C 1000 di klinik ini sangat bagus untuk menambah imunitas.',
    status: {
      title: 'Pending',
      badgeBg: 'warning',
      icon: 'clock'
    },
    time: 'Oct 29, 2024'
  },
  {
    product: 'Kawat Gigi',
    rating: 3,
    review:
      'Layanan pemasangan kawat gigi di klinik ini sangat baik, mulai dari konsultasi hingga perawatan rutin.',
    status: {
      title: 'Cancelled',
      badgeBg: 'secondary',
      icon: 'x'
    },
    time: 'Oct 28, 2024'
  },
  {
    product: 'Konsultasi',
    rating: 4,
    review:
      'Pengalaman konsultasi di klinik sangat menyenangkan dan informatif.',
    status: {
      title: 'Success',
      badgeBg: 'success',
      icon: 'check'
    },
    time: 'Oct 28, 2024'
  }
];
