/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Table, Button, Form, Card, Dropdown } from 'react-bootstrap';
import {
  CustomerTemplateDetailResponse,
  CustomerTemplateFieldDetail
} from 'api/response/CustomerTemplateResponse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCopy,
  faTrash,
  faEllipsisVertical,
  faGripVertical
} from '@fortawesome/free-solid-svg-icons';
import {
  useDeleteCustomerTemplateFieldMutation,
  useGetCustomerTemplateFieldByIdQuery,
  useUpdateTemplateFieldPositionMutation
} from 'api/customerTemplateApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { toast } from 'react-toastify';
import AddTemplateField from 'components/modals/AddTemplateFieldModal';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface BagianPelangganCardProps {
  onTambahBagian: (groupType: string) => void;
  fields: CustomerTemplateFieldDetail[];
  onRefetch: () => void;
  templateResponse?: CustomerTemplateDetailResponse;
}

const CustomToggle = React.forwardRef<any, any>(({ onClick }, ref) => (
  <Button
    ref={ref}
    variant="link"
    className="p-0 text-muted"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faEllipsisVertical} />
  </Button>
));

const SortableRow = ({
  field,
  onEdit,
  onDelete
}: {
  field: CustomerTemplateFieldDetail;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td>
        <div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
          <FontAwesomeIcon icon={faGripVertical} className="text-muted" />
        </div>
      </td>
      <td>{field.fieldName}</td>
      <td>
        {typeof field.fieldType === 'string'
          ? field.fieldType
          : field.fieldType?.name}
      </td>
      <td>
        <Dropdown align="end">
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onEdit(field.id)}>
              <FontAwesomeIcon icon={faEdit} className="me-2" />
              Edit
            </Dropdown.Item>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faCopy} className="me-2" />
              Duplikat
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              className="text-danger"
              onClick={() => onDelete(field.id)}
            >
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

const BagianPelangganCard: React.FC<BagianPelangganCardProps> = ({
  onTambahBagian,
  fields,
  onRefetch,
  templateResponse
}) => {
  const [deleteField] = useDeleteCustomerTemplateFieldMutation();
  const [updatePosition] = useUpdateTemplateFieldPositionMutation();
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const { data: fieldDetail, refetch } = useGetCustomerTemplateFieldByIdQuery(
    Number(selectedFieldId),
    {
      skip: !selectedFieldId,
      refetchOnMountOrArgChange: true
    }
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDelete = async (fieldId: string) => {
    showCustomAlert({
      icon: 'warning',
      label: 'Bagian akan dihapus',
      deskripsi: 'Apakah Anda yakin ingin menghapus bagian ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const response = await deleteField(Number(fieldId)).unwrap();
          if (response.code === 200) {
            await new Promise(resolve => setTimeout(resolve, 300));
            await onRefetch();

            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Bagian berhasil dihapus',
              buttonType: 'ok'
            });
          } else {
            throw new Error(response.message || 'Gagal menghapus bagian');
          }
        } catch (error) {
          console.error('Error deleting field:', error);
          toast.error(
            error instanceof Error ? error.message : 'Gagal menghapus bagian'
          );
        }
      }
    });
  };

  const handleEdit = async (fieldId: string) => {
    setSelectedFieldId(fieldId);
    await new Promise(resolve => setTimeout(resolve, 0));
    refetch();
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedFieldId(null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    try {
      const oldIndex = fields.findIndex(field => field.id === active.id);
      const newIndex = fields.findIndex(field => field.id === over.id);

      await updatePosition({
        fieldId: Number(active.id),
        newPosition: newIndex + 1
      }).unwrap();

      await onRefetch();
    } catch (error) {
      console.error('Error updating position:', error);
      toast.error('Gagal mengubah posisi field');
    }
  };

  return (
    <>
      <Card className="mt-2 p-2">
        <div className="container mt-4">
          <h3 className="mb-4">Bagian Pelanggan</h3>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '40px' }}></th>
                  <th>NAMA BAGIAN</th>
                  <th>JENIS BAGIAN</th>
                  <th style={{ width: '40px' }}></th>
                </tr>
              </thead>
              <tbody>
                <SortableContext
                  items={fields.map(f => f.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map(field => (
                    <SortableRow
                      key={field.id}
                      field={field}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  ))}
                </SortableContext>
              </tbody>
            </Table>
          </DndContext>

          <div className="d-grid">
            <Button
              variant="outline-primary"
              onClick={() => onTambahBagian('Bagian Pelanggan')}
              className="mt-3"
            >
              + Tambah Bagian
            </Button>
          </div>
        </div>
      </Card>

      {showEditModal && selectedFieldId && (
        <AddTemplateField
          show={showEditModal}
          handleClose={handleCloseEditModal}
          templateId={Number(templateResponse?.data?.id)}
          defaultGroupType="Bagian Pelanggan"
          onSuccess={() => {
            onRefetch();
            handleCloseEditModal();
          }}
          isEdit={true}
          editData={fieldDetail?.data}
        />
      )}
    </>
  );
};

export default BagianPelangganCard;
