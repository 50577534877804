import { Dropdown, Form } from 'react-bootstrap';

interface CheckboxDropdownItemProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
}

const CheckboxDropdownItem = ({
  label,
  value,
  checked,
  onChange
}: CheckboxDropdownItemProps) => {
  return (
    <Dropdown.Item
      as="div"
      className="d-flex align-items-center px-3 py-2"
      onClick={e => e.stopPropagation()}
    >
      <Form.Check
        type="checkbox"
        id={`checkbox-${value}`}
        label={label}
        checked={checked}
        onChange={e => onChange(value, e.target.checked)}
        className="w-100"
      />
    </Dropdown.Item>
  );
};

export default CheckboxDropdownItem;
