import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarUpload from 'components/common/AvatarUpload';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import {
  useEditCustomerMutation,
  useGetCustomerByIdQuery
} from 'api/customerApi';
import { useParams } from 'react-router-dom';
import team15 from 'assets/img/team/15.webp';
import { showCustomAlert } from 'helpers/showCustomAlert';

const CustomerProfileCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: response, isLoading, error } = useGetCustomerByIdQuery(id!);
  const [editCustomer, { isLoading: isSaving }] = useEditCustomerMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    joinDate: ''
  });
  const [errors, setErrors] = useState({
    firstName: ''
  });

  useEffect(() => {
    if (response?.data?.master) {
      setFormData({
        firstName: response.data.master.names?.firstName || '-',
        lastName: response.data.master.names?.lastName || '-',
        joinDate: response.data.master.joinedDate?.formatted || '-'
      });
    }
    if (!id) {
      console.warn('ID tidak ditemukan di parameter URL.');
    }
  }, [response, id]);

  const handleCancel = () => {
    // Cek apakah ada perubahan data dengan membandingkan data saat ini dengan data awal
    const hasChanges =
      response?.data?.master &&
      (formData.firstName !== response.data.master.names.firstName ||
        formData.lastName !== response.data.master.names.lastName);

    if (!hasChanges) {
      setIsEditing(false);
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Batalkan Perubahan',
      deskripsi:
        'Anda yakin ingin membatalkan perubahan data profil? Semua perubahan yang belum disimpan akan hilang.',
      buttonType: 'yes-no',
      onConfirm: () => {
        setIsEditing(false);
        // Reset form ke data awal
        if (response?.data?.master) {
          setFormData({
            firstName: response.data.master.names.firstName,
            lastName: response.data.master.names.lastName,
            joinDate: response.data.master.joinedDate.formatted
          });
        }
      }
    });
  };

  const handleEditClick = () => setIsEditing(true);

  const handleSave = async () => {
    // Validasi nama depan
    if (!formData.firstName.trim()) {
      setErrors(prev => ({
        ...prev,
        firstName: 'Nama depan harus diisi'
      }));
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Simpan Perubahan',
      deskripsi: 'Anda yakin ingin menyimpan perubahan data profil ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const dataToSend = {
            ...formData,
            id: parseInt(id || '0', 10),
            names: {
              firstName: formData.firstName,
              lastName: formData.lastName
            }
          };

          await editCustomer(dataToSend).unwrap();

          // Tampilkan alert success
          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data profil berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Gagal memperbarui data:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi:
              error?.data?.message || 'Terjadi kesalahan, silakan coba lagi.',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    // Reset error saat input berubah
    if (name === 'firstName') {
      setErrors(prev => ({
        ...prev,
        firstName: ''
      }));
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) {
    console.error('Error loading customer:', error);
    return <div>Error loading customer data</div>;
  }
  if (!response?.data?.master) return <div>No customer data found</div>;

  return (
    <Card className="w-full">
      <Card.Body>
        <Row className="align-items-center g-5 text-center text-sm-start">
          <Col xs={12} sm="auto" className="mb-sm-3">
            <AvatarUpload size={isEditing ? '5xl' : '3xl'} src={team15} />
          </Col>
          <Col xs={12} sm="auto" className="flex-1">
            {isEditing ? (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Nama Depan <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Nama Belakang
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label className="fs-8 ps-0 mb-2 text-capitalize">
                    Tanggal Bergabung
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="joinDate"
                    value={formData.joinDate}
                    onChange={handleInputChange}
                    disabled
                  />
                </Form.Group>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <h4 className="fw-bolder">
                    {formData.firstName || '-'} {formData.lastName || '-'}
                  </h4>
                  <Button variant="ghost" size="sm" onClick={handleEditClick}>
                    <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                  </Button>
                </div>
                <p className="text-body-secondary">
                  Bergabung pada {formData.joinDate || '-'}
                </p>
              </>
            )}
          </Col>
        </Row>
        {!isEditing && (
          <Col
            xs={12}
            className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-2 mt-0 mb-3"
          >
            <Badge
              style={{ borderRadius: '8px' }}
              bg="warning"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3"
            >
              BOOKING: 1
            </Badge>
            <Badge
              style={{ borderRadius: '8px' }}
              bg="danger"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3"
            >
              TIDAK DATANG: 1
            </Badge>
            <Badge
              style={{ borderRadius: '8px' }}
              bg="success"
              variant="phoenix"
              className="fs-10 text-center flex-grow-1 py-2 px-3"
            >
              KUNJUNGAN TERAKHIR: 21 OKTOBER 2024
            </Badge>
          </Col>
        )}

        {!isEditing && (
          <div className="d-flex flex-between-center border-top border-dashed pt-4 fw-bold">
            <div>
              <h6>Total Spent</h6>
              <p className="fs-7 text-body-secondary mb-0">Rp1.600k</p>
            </div>
            <div>
              <h6>Debt Balance</h6>
              <p className="fs-7 text-body-secondary mb-0">Rp0</p>
            </div>
            <div>
              <h6>Credit Balance</h6>
              <p className="fs-7 text-body-secondary mb-0">Rp0</p>
            </div>
            <div>
              <h6>Last Order</h6>
              <p className="fs-7 text-body-secondary mb-0">1 week ago</p>
            </div>
            <div>
              <h6>Total Order</h6>
              <p className="fs-7 text-body-secondary mb-0">3</p>
            </div>
          </div>
        )}

        {isEditing && (
          <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={handleCancel}
            >
              Batalkan
            </Button>
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? 'Menyimpan...' : 'Simpan'}
            </Button>{' '}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default CustomerProfileCard;
