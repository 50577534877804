import { useState, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

export interface FilterItem {
  label: string;
  value?: string;
  checked?: boolean;
  isSelected?: boolean;
}

export interface FilterMenu {
  label: string;
  items: FilterItem[];
  isMultiple?: boolean;
}

interface FilterButtonGroupProps {
  menus: FilterMenu[];
  onFilterChange?: (label: string, selectedItems: FilterItem[]) => void;
}

const FilterButtonGroup = ({
  menus,
  onFilterChange
}: FilterButtonGroupProps) => {
  const [localMenus, setLocalMenus] = useState<FilterMenu[]>(menus);

  useEffect(() => {
    setLocalMenus(menus);
  }, [menus]);

  const handleCheckboxChange = (menuLabel: string, itemIndex: number) => {
    const updatedMenus = localMenus.map(menu => {
      if (menu.label === menuLabel) {
        const updatedItems = [...menu.items];
        updatedItems[itemIndex] = {
          ...updatedItems[itemIndex],
          checked: !updatedItems[itemIndex].checked
        };

        if (onFilterChange) {
          onFilterChange(
            menuLabel,
            updatedItems.filter(item => item.checked)
          );
        }

        return {
          ...menu,
          items: updatedItems
        };
      }
      return menu;
    });
    setLocalMenus(updatedMenus);
  };

  return (
    <div className="d-flex gap-2">
      {localMenus.map((menu, menuIndex) => (
        <Dropdown key={menuIndex}>
          <Dropdown.Toggle variant="outline-secondary" size="sm">
            {menu.label}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ minWidth: '200px', padding: '8px' }}>
            {menu.items.map((item, itemIndex) => (
              <div key={itemIndex} className="px-2 py-1">
                <Form.Check
                  type="checkbox"
                  id={`${menu.label}-${itemIndex}`}
                  label={item.label}
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(menu.label, itemIndex)}
                />
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </div>
  );
};

export default FilterButtonGroup;
