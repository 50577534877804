import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: builder => ({
    getProvinces: builder.query<
      {
        code: number;
        message: string;
        data: Array<{ id: string; name: string }>;
      },
      void
    >({
      query: () => '/api/v1/province'
    }),
    getRegencies: builder.query<
      {
        code: number;
        message: string;
        data: Array<{ id: string; name: string }>;
      },
      string
    >({
      query: provinceId => `/api/v1/regency/${provinceId}/province`
    }),
    getDistricts: builder.query<
      {
        code: number;
        message: string;
        data: Array<{ id: string; name: string }>;
      },
      string
    >({
      query: regencyId => `/api/v1/district/${regencyId}/regency`
    }),
    getVillages: builder.query<
      {
        code: number;
        message: string;
        data: Array<{ id: string; name: string }>;
      },
      string
    >({
      query: districtId => `/api/v1/village/${districtId}/district`
    })
  })
});

export const {
  useGetProvincesQuery,
  useGetRegenciesQuery,
  useGetDistrictsQuery,
  useGetVillagesQuery
} = locationApi;
