import React, { useState, useMemo } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { showCustomAlert } from 'helpers/showCustomAlert';
import type {
  CustomerTemplateFieldDetail,
  CustomerTemplate
} from 'api/response/CustomerTemplateResponse';
import { useGetCustomerTemplateResponseQuery } from 'api/customerTemplateApi';
import { useUpdateCustomerTemplateResponseMutation } from 'api/customerTemplateApi';
import Select from 'react-select';

interface CustomerTemplateCardProps {
  template: CustomerTemplate;
  customerId: string;
  localTemplateFields?: {
    data?: CustomerTemplateFieldDetail[];
  };
}

const CustomerTemplateCard: React.FC<CustomerTemplateCardProps> = ({
  template,
  customerId
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedResponses, setEditedResponses] = useState<{
    [key: string]: {
      responseValue?: string;
      options?: { id: string; selectedChoice: boolean }[];
    };
  }>({});
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const { data: templateResponseData, refetch } =
    useGetCustomerTemplateResponseQuery();
  const [updateTemplateResponse] = useUpdateCustomerTemplateResponseMutation();

  const customerTemplateResponses = useMemo(() => {
    if (!templateResponseData?.data) return [];

    const filteredResponses = templateResponseData.data.filter(
      response =>
        response.customer &&
        response.customer.id === customerId &&
        response.field &&
        response.field.fieldGroup === 'customer' &&
        template.customerTemplateFields.some(
          templateField => templateField.id === response.field.id
        )
    );

    return filteredResponses.sort((a, b) => {
      const fieldA = template.customerTemplateFields.find(
        field => field.id === a.field.id
      );
      const fieldB = template.customerTemplateFields.find(
        field => field.id === b.field.id
      );
      return (fieldA?.position || 0) - (fieldB?.position || 0);
    });
  }, [templateResponseData, customerId, template.customerTemplateFields]);

  const validateFields = () => {
    const errors: { [key: string]: string } = {};

    customerTemplateResponses.forEach(response => {
      const { field } = response;
      if (field.isRequired) {
        const editedResponse = editedResponses[field.id];

        switch (field.fieldType.code) {
          case 'CHECKBOXES': {
            // Cek apakah ada opsi yang dipilih dari edited responses
            const editedOptions = editedResponse?.options;
            if (editedOptions) {
              // Jika sudah diedit, cek dari edited options
              const hasSelected = editedOptions.some(opt => opt.selectedChoice);
              if (!hasSelected) {
                errors[field.id] = 'Data tidak boleh kosong';
              }
            } else {
              // Jika belum diedit, cek dari response asli
              const hasSelected = response.customerTemplateOptionResponse.some(
                opt => opt.selectedChoice
              );
              if (!hasSelected) {
                errors[field.id] = 'Data tidak boleh kosong';
              }
            }
            break;
          }
          case 'RADIO_BUTTONS':
          case 'DROPDO{!!  !!}LIST': {
            const hasSelectedOption =
              editedResponse?.options?.some(opt => opt.selectedChoice) ||
              response.customerTemplateOptionResponse.some(
                opt => opt.selectedChoice
              );
            if (!hasSelectedOption) {
              errors[field.id] = 'Data tidak boleh kosong';
            }
            break;
          }
          default: {
            const value =
              editedResponse?.responseValue ?? response.responseValue;
            if (!value || value.trim() === '') {
              errors[field.id] = 'Data tidak boleh kosong';
            }
          }
        }
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderTemplateFields = () => {
    if (
      !customerTemplateResponses.length ||
      !customerTemplateResponses[0]?.field
    )
      return null;

    return customerTemplateResponses.map(response => {
      const { field } = response;
      if (!field) return null;

      return !isEditing ? (
        <Row className="mb-3" key={field.id}>
          <Col xs={12} md={12} className="d-flex flex-column pe-3">
            <span className="fw-bold text-muted">{field.label || '-'}</span>
            <span className="fw-normal">
              {field.fieldType.code === 'CHECKBOXES' ? (
                // Render untuk checkbox
                <div>
                  {field.customerTemplateFieldOption.map((option, idx) => {
                    const responseOption =
                      response.customerTemplateOptionResponse[idx];
                    if (responseOption?.selectedChoice) {
                      return <div key={option.id}>{option.optionValue}</div>;
                    }
                    return null;
                  })}
                </div>
              ) : field.fieldType.code === 'MULTI_SELECT_LIST' ? (
                <div>
                  {response.responseValue
                    ? response.responseValue
                        .replace(/[{}]/g, '')
                        .split(',')
                        .map(value => value.trim().replace(/"/g, ''))
                        .join(', ')
                    : '-'}
                </div>
              ) : field.fieldType.code === 'RADIO_BUTTONS' ? (
                // Render untuk radio button
                <div>
                  {field.customerTemplateFieldOption.map((option, idx) => {
                    const responseOption =
                      response.customerTemplateOptionResponse[idx];
                    if (responseOption?.selectedChoice) {
                      return <div key={option.id}>{option.optionValue}</div>;
                    }
                    return null;
                  })}
                </div>
              ) : field.fieldType.code === 'DROPDOWN_LIST' ? (
                // Render untuk dropdown
                <div>
                  {field.customerTemplateFieldOption.map((option, idx) => {
                    const responseOption =
                      response.customerTemplateOptionResponse[idx];
                    if (responseOption?.selectedChoice) {
                      return <div key={option.id}>{option.optionValue}</div>;
                    }
                    return null;
                  })}
                </div>
              ) : field.fieldType.code === 'MULTIPLE_LINE_TEXT' ? (
                // Render untuk multiple line text - tampilkan sebagai teks biasa dengan line break
                <div style={{ whiteSpace: 'pre-line' }}>
                  {response.responseValue || '-'}
                </div>
              ) : field.fieldType.code === 'DATE' ? (
                // Render untuk tipe date - tampilkan dalam format yang lebih readable
                <div>
                  {response.responseValue
                    ? new Date(response.responseValue).toLocaleDateString(
                        'id-ID',
                        {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric'
                        }
                      )
                    : '-'}
                </div>
              ) : (
                // Untuk tipe lain (text, textarea, dll)
                response.responseValue || '-'
              )}
            </span>
          </Col>
        </Row>
      ) : (
        <Form.Group key={field.id} className="mb-3">
          <Form.Label className="fw-bolder p-0 fs-8 text-capitalize">
            {field.label}
            {field.isRequired && <span className="text-danger">*</span>}
          </Form.Label>
          {field.fieldType.code === 'CHECKBOXES' ? (
            <div>
              {[...field.customerTemplateFieldOption]
                .sort((a, b) => a.optionValue.localeCompare(b.optionValue))
                .map((option, idx) => {
                  const currentResponse =
                    response.customerTemplateOptionResponse[
                      field.customerTemplateFieldOption.findIndex(
                        opt => opt.id === option.id
                      )
                    ];
                  return (
                    <Form.Check
                      key={option.id}
                      type="checkbox"
                      label={option.optionValue}
                      checked={
                        editedResponses[field.id]?.options?.[idx]
                          ?.selectedChoice ?? currentResponse?.selectedChoice
                      }
                      onChange={() => {
                        const currentOptions =
                          editedResponses[field.id]?.options ||
                          response.customerTemplateOptionResponse.map(opt => ({
                            id: opt.id,
                            selectedChoice: opt.selectedChoice
                          }));

                        setEditedResponses(prev => ({
                          ...prev,
                          [field.id]: {
                            options: currentOptions.map((opt, optIdx) =>
                              optIdx === idx
                                ? {
                                    ...opt,
                                    selectedChoice: !opt.selectedChoice
                                  }
                                : opt
                            )
                          }
                        }));
                      }}
                    />
                  );
                })}
              {fieldErrors[field.id] && (
                <div className="text-danger small mt-1">
                  {fieldErrors[field.id]}
                </div>
              )}
            </div>
          ) : field.fieldType.code === 'RADIO_BUTTONS' ? (
            <div>
              {[...field.customerTemplateFieldOption]
                .sort((a, b) => a.optionValue.localeCompare(b.optionValue))
                .map((option, idx) => {
                  const currentResponse =
                    response.customerTemplateOptionResponse[
                      field.customerTemplateFieldOption.findIndex(
                        opt => opt.id === option.id
                      )
                    ];
                  return (
                    <Form.Check
                      key={option.id}
                      type="radio"
                      name={`radio-${field.id}`}
                      label={option.optionValue}
                      checked={
                        editedResponses[field.id]?.options?.[idx]
                          ?.selectedChoice ?? currentResponse?.selectedChoice
                      }
                      onChange={() => {
                        setEditedResponses(prev => ({
                          ...prev,
                          [field.id]: {
                            options: field.customerTemplateFieldOption.map(
                              (_, optIdx) => ({
                                id: response.customerTemplateOptionResponse[
                                  optIdx
                                ].id,
                                selectedChoice: idx === optIdx
                              })
                            )
                          }
                        }));
                      }}
                    />
                  );
                })}
              {fieldErrors[field.id] && (
                <div className="text-danger small mt-1">
                  {fieldErrors[field.id]}
                </div>
              )}
            </div>
          ) : field.fieldType.code === 'MULTI_SELECT_LIST' ? (
            // Mode edit untuk multiple select list
            <Select
              isMulti
              name={field.fieldName}
              className="custom-select"
              classNamePrefix="select"
              styles={{
                option: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                multiValue: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                clearIndicator: base => ({
                  ...base,
                  cursor: 'pointer'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  cursor: 'pointer'
                })
              }}
              value={
                editedResponses[field.id]?.responseValue ||
                response.responseValue
                  ? (
                      editedResponses[field.id]?.responseValue ||
                      response.responseValue
                    )
                      .replace(/[{}]/g, '')
                      .split(',')
                      .map(value => ({
                        value: value.trim().replace(/"/g, ''),
                        label: value.trim().replace(/"/g, '')
                      }))
                  : []
              }
              onChange={selectedOptions => {
                const selectedValues = selectedOptions.map(
                  option => option.value
                );
                setEditedResponses(prev => ({
                  ...prev,
                  [field.id]: {
                    responseValue: selectedValues.length
                      ? `{${selectedValues.map(v => `"${v}"`).join(',')}}`
                      : ''
                  }
                }));
              }}
              options={[...field.customerTemplateFieldOption]
                .sort((a, b) => a.optionValue.localeCompare(b.optionValue))
                .map(option => ({
                  value: option.optionValue,
                  label: option.optionValue
                }))}
              placeholder={`Pilih ${field.label}`}
            />
          ) : field.fieldType.code === 'DROPDOWN_LIST' ? (
            <>
              <Form.Select
                value={field.customerTemplateFieldOption.findIndex(
                  (_, idx) =>
                    editedResponses[field.id]?.options?.[idx]?.selectedChoice ??
                    response.customerTemplateOptionResponse[idx]?.selectedChoice
                )}
                onChange={e => {
                  const selectedIndex = Number(e.target.value);
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      options: field.customerTemplateFieldOption.map(
                        (_, idx) => ({
                          id: response.customerTemplateOptionResponse[idx].id,
                          selectedChoice: idx === selectedIndex
                        })
                      )
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
              >
                {[...field.customerTemplateFieldOption]
                  .sort((a, b) => a.optionValue.localeCompare(b.optionValue))
                  .map((option, idx) => (
                    <option key={option.id} value={idx}>
                      {option.optionValue}
                    </option>
                  ))}
              </Form.Select>
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          ) : field.fieldType.code === 'DATE' ? (
            // Mode edit untuk tipe date
            <>
              <Form.Control
                type="date"
                value={
                  editedResponses[field.id]?.responseValue
                    ? new Date(editedResponses[field.id].responseValue || '')
                        .toISOString()
                        .split('T')[0]
                    : response.responseValue
                    ? new Date(response.responseValue || '')
                        .toISOString()
                        .split('T')[0]
                    : ''
                }
                onChange={e => {
                  const dateValue = e.target.value
                    ? new Date(e.target.value).toISOString()
                    : '';
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      responseValue: dateValue
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
              />
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          ) : (
            // Untuk tipe input text biasa dan multiple line text
            <>
              <Form.Control
                as={
                  field.fieldType.code === 'MULTIPLE_LINE_TEXT'
                    ? 'textarea'
                    : 'input'
                }
                {...(field.fieldType.code === 'MULTIPLE_LINE_TEXT'
                  ? { rows: 3 }
                  : {})}
                value={
                  editedResponses[field.id]?.responseValue ??
                  response.responseValue
                }
                onChange={e => {
                  setEditedResponses(prev => ({
                    ...prev,
                    [field.id]: {
                      responseValue: e.target.value
                    }
                  }));
                }}
                isInvalid={!!fieldErrors[field.id]}
              />
              {fieldErrors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {fieldErrors[field.id]}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>
      );
    });
  };

  const hasChanges = () => {
    return Object.keys(editedResponses).length > 0;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: 'Konfirmasi Simpan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const updatePayload = customerTemplateResponses.map(response => {
            const editedResponse = editedResponses[response.field.id];

            return {
              customerTemplateResponseId: response.id,
              customerId: Number(customerId),
              fieldId: Number(response.field.id),
              responseValue:
                editedResponse?.responseValue || response.responseValue,
              ...(response.field.fieldType.code === 'CHECKBOXES' ||
              response.field.fieldType.code === 'RADIO_BUTTONS' ||
              response.field.fieldType.code === 'DROPDOWN_LIST'
                ? {
                    optionResponse: response.customerTemplateOptionResponse.map(
                      (option, idx) => ({
                        id: Number(option.id),
                        optionId: Number(
                          response.field.customerTemplateFieldOption[idx].id
                        ),
                        selectedChoice:
                          editedResponse?.options?.[idx]?.selectedChoice ??
                          option.selectedChoice
                      })
                    )
                  }
                : {})
            };
          });

          await updateTemplateResponse(updatePayload).unwrap();

          showCustomAlert({
            icon: 'success',
            label: 'Berhasil',
            deskripsi: 'Data template berhasil diperbarui',
            buttonType: 'ok',
            onConfirm: () => {
              setIsEditing(false);
              setEditedResponses({});
              refetch(); // Refresh data setelah berhasil update
            }
          });
        } catch (error) {
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi: 'Terjadi kesalahan saat menyimpan data',
            buttonType: 'ok'
          });
        }
      }
    });
  };

  const handleCancel = () => {
    if (hasChanges()) {
      showCustomAlert({
        icon: 'warning',
        label: 'Konfirmasi Batal',
        deskripsi:
          'Perubahan yang Anda buat akan hilang. Apakah Anda yakin ingin membatalkan?',
        buttonType: 'yes-no',
        onConfirm: () => {
          setIsEditing(false);
          setEditedResponses({});
        }
      });
    } else {
      setIsEditing(false);
    }
  };

  return (
    <Card className="w-100">
      <Card.Body>
        <Row className="mb-4">
          <Col xs={12} className="d-flex align-items-center">
            <h3>{template?.templateName || '-'}</h3>
            {!isEditing && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEditing(true)}
              >
                <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
              </Button>
            )}
          </Col>
        </Row>

        {!isEditing ? (
          <div>{renderTemplateFields()}</div>
        ) : (
          <Form>
            {renderTemplateFields()}

            <div className="d-flex justify-content-end gap-1 pt-4 mt-4 border-top border-dashed">
              <Button
                variant="link"
                style={{ color: 'red' }}
                onClick={handleCancel}
              >
                Batalkan
              </Button>
              <Button onClick={handleSave}>Simpan</Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};

export default CustomerTemplateCard;
