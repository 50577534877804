/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import {
  useAddCustomerTemplateFieldMutation,
  useGetCustomerTemplateFieldQuery,
  useGetCustomerTemplateByIdQuery,
  useEditCustomerTemplateFieldMutation
} from '../../api/customerTemplateApi';
import { showCustomAlert } from 'helpers/showCustomAlert';
import {
  AddTemplateFieldRequest,
  CustomerTemplateFieldDetail,
  CustomerTemplateDetailResponse
} from 'api/response/CustomerTemplateResponse';

interface AddTemplateFieldProps {
  show: boolean;
  handleClose: () => void;
  templateId: number;
  defaultGroupType: string;
  onSuccess?: () => void;
  isEdit?: boolean;
  editData?: CustomerTemplateFieldDetail;
}

interface RadioOption {
  id?: string;
  value: string;
  isDefault: boolean;
}

const AddTemplateField: React.FC<AddTemplateFieldProps> = ({
  show,
  handleClose,
  templateId,
  defaultGroupType,
  onSuccess,
  isEdit = false,
  editData
}) => {
  // Tambahkan hook untuk get template by id
  const { data: templateData } = useGetCustomerTemplateByIdQuery(
    templateId
  ) as {
    data?: CustomerTemplateDetailResponse;
  };

  // Common states
  const [templateName, setTemplateName] = useState('');
  const [fieldType, setFieldType] = useState('Single line text');
  const [groupType, setGroupType] = useState('');

  // Tambahkan useEffect untuk mengatur groupType saat modal dibuka
  useEffect(() => {
    if (show) {
      setGroupType(defaultGroupType);
    }
  }, [show, defaultGroupType]);

  // Single line text states
  const [singleLineLabel, setSingleLineLabel] = useState('');
  const [singleLineRequired, setSingleLineRequired] = useState('');
  const [singleLineDisplaySize, setSingleLineDisplaySize] = useState('Medium');
  const [singleLineMaxLength, setSingleLineMaxLength] = useState('128');

  // Multiple line text states
  const [multiLineLabel, setMultiLineLabel] = useState('');
  const [multiLineRequired, setMultiLineRequired] = useState('');
  const [multiLineDisplaySize, setMultiLineDisplaySize] = useState('Medium');
  const [multiLineMaxLength, setMultiLineMaxLength] = useState('1000');
  const [multiLineRows, setMultiLineRows] = useState('3');

  // Radio buttons states
  const [radioLabel, setRadioLabel] = useState('');
  const [radioRequired, setRadioRequired] = useState('');
  const [radioOptions, setRadioOptions] = useState<RadioOption[]>([
    { value: '', isDefault: true },
    { value: '', isDefault: false }
  ]);

  // Checkboxes states
  const [checkboxLabel, setCheckboxLabel] = useState('');
  const [checkboxRequired, setCheckboxRequired] = useState('');
  const [checkboxOptions, setCheckboxOptions] = useState<RadioOption[]>([
    { value: '', isDefault: true },
    { value: '', isDefault: false }
  ]);

  // Dropdown list states
  const [dropdownLabel, setDropdownLabel] = useState('');
  const [dropdownRequired, setDropdownRequired] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState<RadioOption[]>([
    { value: '', isDefault: true },
    { value: '', isDefault: false }
  ]);

  // Multi select list states
  const [multiSelectLabel, setMultiSelectLabel] = useState('');
  const [multiSelectRequired, setMultiSelectRequired] = useState('');
  const [multiSelectOptions, setMultiSelectOptions] = useState<RadioOption[]>([
    { value: '', isDefault: true },
    { value: '', isDefault: false }
  ]);

  // Date states
  const [dateLabel, setDateLabel] = useState('');
  const [dateRequired, setDateRequired] = useState('');
  const [dateFormat, setDateFormat] = useState('DD/MM/YYYY');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  // Checkboxes states
  const [checkboxDesign, setCheckboxDesign] = useState('Box');
  const [checkboxLayout, setCheckboxLayout] = useState('Horizontal');

  // Radio button handlers
  const handleAddRadioOption = () => {
    setRadioOptions([...radioOptions, { value: '', isDefault: false }]);
  };

  const handleRemoveRadioOption = (index: number) => {
    const newOptions = radioOptions.filter((_, i) => i !== index);
    if (radioOptions[index].isDefault && newOptions.length > 0) {
      newOptions[0].isDefault = true;
    }
    setRadioOptions(newOptions);
  };

  const handleSetRadioDefault = (index: number) => {
    setRadioOptions(
      radioOptions.map((option, i) => ({
        ...option,
        isDefault: i === index
      }))
    );
  };

  // Checkbox handlers
  const handleAddCheckboxOption = () => {
    setCheckboxOptions([...checkboxOptions, { value: '', isDefault: false }]);
  };

  const handleRemoveCheckboxOption = (index: number) => {
    setCheckboxOptions(checkboxOptions.filter((_, i) => i !== index));
  };

  const handleToggleCheckboxDefault = (index: number) => {
    setCheckboxOptions(
      checkboxOptions.map((option, i) => ({
        ...option,
        isDefault: i === index ? !option.isDefault : option.isDefault
      }))
    );
  };

  // Dropdown handlers
  const handleAddDropdownOption = () => {
    setDropdownOptions([...dropdownOptions, { value: '', isDefault: false }]);
  };

  const handleRemoveDropdownOption = (index: number) => {
    const newOptions = dropdownOptions.filter((_, i) => i !== index);
    if (dropdownOptions[index].isDefault && newOptions.length > 0) {
      newOptions[0].isDefault = true;
    }
    setDropdownOptions(newOptions);
  };

  const handleSetDropdownDefault = (index: number) => {
    setDropdownOptions(
      dropdownOptions.map((option, i) => ({
        ...option,
        isDefault: i === index
      }))
    );
  };

  // Multi select handlers
  const handleAddMultiSelectOption = () => {
    setMultiSelectOptions([
      ...multiSelectOptions,
      { value: '', isDefault: false }
    ]);
  };

  const handleRemoveMultiSelectOption = (index: number) => {
    setMultiSelectOptions(multiSelectOptions.filter((_, i) => i !== index));
  };

  const handleToggleMultiSelectDefault = (index: number) => {
    setMultiSelectOptions(
      multiSelectOptions.map((option, i) => ({
        ...option,
        isDefault: i === index ? !option.isDefault : option.isDefault
      }))
    );
  };

  // Tambahkan state untuk error
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Fungsi validasi
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    // Validasi nama template
    if (!templateName.trim()) {
      newErrors.templateName = 'Nama bagian harus diisi';
    }

    // Validasi berdasarkan tipe field
    switch (fieldType) {
      case 'Single line text':
        if (!singleLineLabel.trim()) {
          newErrors.singleLineLabel = 'Label harus diisi';
        }
        if (!singleLineRequired) {
          newErrors.singleLineRequired = 'Sifat bagian harus dipilih';
        }
        break;
      case 'Multiple line text':
        if (!multiLineLabel.trim()) {
          newErrors.multiLineLabel = 'Label harus diisi';
        }
        if (!multiLineRequired) {
          newErrors.multiLineRequired = 'Sifat bagian harus dipilih';
        }
        break;
      case 'Radio buttons':
        if (!radioLabel.trim()) {
          newErrors.radioLabel = 'Label harus diisi';
        }
        if (!radioRequired) {
          newErrors.radioRequired = 'Sifat bagian harus dipilih';
        }
        if (radioOptions.length === 0) {
          newErrors.radioOptions = 'Minimal harus ada satu pilihan';
        } else if (radioOptions.some(opt => !opt.value.trim())) {
          newErrors.radioOptions = 'Semua pilihan harus diisi';
        }
        break;
      case 'Checkboxes':
        if (!checkboxLabel.trim()) {
          newErrors.checkboxLabel = 'Label harus diisi';
        }
        if (!checkboxRequired) {
          newErrors.checkboxRequired = 'Sifat bagian harus dipilih';
        }
        if (checkboxOptions.length === 0) {
          newErrors.checkboxOptions = 'Minimal harus ada satu pilihan';
        } else if (checkboxOptions.some(opt => !opt.value.trim())) {
          newErrors.checkboxOptions = 'Semua pilihan harus diisi';
        }
        break;
      case 'Dropdown list':
        if (!dropdownLabel.trim()) {
          newErrors.dropdownLabel = 'Label harus diisi';
        }
        if (!dropdownRequired) {
          newErrors.dropdownRequired = 'Sifat bagian harus dipilih';
        }
        if (dropdownOptions.length === 0) {
          newErrors.dropdownOptions = 'Minimal harus ada satu pilihan';
        } else if (dropdownOptions.some(opt => !opt.value.trim())) {
          newErrors.dropdownOptions = 'Semua pilihan harus diisi';
        }
        break;
      case 'Multi select list':
        if (!multiSelectLabel.trim()) {
          newErrors.multiSelectLabel = 'Label harus diisi';
        }
        if (!multiSelectRequired) {
          newErrors.multiSelectRequired = 'Sifat bagian harus dipilih';
        }
        if (multiSelectOptions.length === 0) {
          newErrors.multiSelectOptions = 'Minimal harus ada satu pilihan';
        } else if (multiSelectOptions.some(opt => !opt.value.trim())) {
          newErrors.multiSelectOptions = 'Semua pilihan harus diisi';
        }
        break;
      case 'Date':
        if (!dateLabel.trim()) {
          newErrors.dateLabel = 'Label harus diisi';
        }
        if (!dateRequired) {
          newErrors.dateRequired = 'Sifat bagian harus dipilih';
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const renderDisplaySection = () => {
    switch (fieldType) {
      case 'Single line text':
        return (
          <>
            <Form.Group controlId="singleLineLabel" className="mb-3">
              <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                Label<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan label"
                value={singleLineLabel}
                onChange={e => setSingleLineLabel(e.target.value)}
                isInvalid={!!errors.singleLineLabel}
              />
              <Form.Control.Feedback type="invalid">
                {errors.singleLineLabel}
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="singleLineRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian<span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Select
                    value={singleLineRequired}
                    onChange={e => setSingleLineRequired(e.target.value)}
                    isInvalid={!!errors.singleLineRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.singleLineRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="singleLineMaxLength">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Panjang Maksimal
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={singleLineMaxLength}
                    onChange={e => setSingleLineMaxLength(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        );

      case 'Multiple line text':
        return (
          <>
            <Form.Group controlId="multiLineLabel" className="mb-3">
              <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                Label<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan label"
                value={multiLineLabel}
                onChange={e => setMultiLineLabel(e.target.value)}
                isInvalid={!!errors.multiLineLabel}
              />
              <Form.Control.Feedback type="invalid">
                {errors.multiLineLabel}
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="multiLineRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian<span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Select
                    value={multiLineRequired}
                    onChange={e => setMultiLineRequired(e.target.value)}
                    isInvalid={!!errors.multiLineRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.multiLineRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="multiLineMaxLength">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Panjang Maksimal
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={multiLineMaxLength}
                    onChange={e => setMultiLineMaxLength(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        );
      case 'Radio buttons':
        return (
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="radioLabel">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Label<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan pilihan"
                    value={radioLabel}
                    onChange={e => setRadioLabel(e.target.value)}
                    isInvalid={!!errors.radioLabel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.radioLabel}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="radioRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={radioRequired}
                    onChange={e => setRadioRequired(e.target.value)}
                    isInvalid={!!errors.radioRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.radioRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                <h5 className="mb-2">
                  Pilihan<span className="text-danger">*</span>
                </h5>
              </Form.Label>
              <div className={` ${errors.radioOptions ? 'border-danger' : ''}`}>
                <div className="d-flex mb-2">
                  <div style={{ width: '80px' }}>Default</div>
                  <div className="flex-grow-1">Isi Pilihan</div>
                </div>
                {radioOptions.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex mb-2 align-items-center gap-2"
                  >
                    <div style={{ width: '80px' }}>
                      <Form.Check
                        type="checkbox"
                        checked={option.isDefault}
                        onChange={() => handleSetRadioDefault(index)}
                        className="ms-3 custom-checkbox"
                        style={
                          {
                            '--bs-form-check-bg': option.isDefault
                              ? '#fff'
                              : '#000'
                          } as React.CSSProperties
                        }
                      />
                    </div>
                    <Form.Control
                      type="text"
                      placeholder="Masukan pilihan"
                      value={option.value}
                      onChange={e => {
                        const newOptions = [...radioOptions];
                        newOptions[index].value = e.target.value;
                        setRadioOptions(newOptions);
                      }}
                      className="flex-grow-1"
                    />
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleRemoveRadioOption(index)}
                      disabled={radioOptions.length <= 1}
                    >
                      -
                    </Button>
                  </div>
                ))}
                <div className="mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={handleAddRadioOption}
                    className="w-100"
                  >
                    + Tambah Pilihan
                  </Button>
                </div>
                {errors.radioOptions && (
                  <div className="text-danger mt-1 small">
                    {errors.radioOptions}
                  </div>
                )}
              </div>
            </Form.Group>
          </>
        );

      case 'Checkboxes':
        return (
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="checkboxLabel">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Label<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan pilihan"
                    value={checkboxLabel}
                    onChange={e => setCheckboxLabel(e.target.value)}
                    isInvalid={!!errors.checkboxLabel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.checkboxLabel}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="checkboxRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={checkboxRequired}
                    onChange={e => setCheckboxRequired(e.target.value)}
                    isInvalid={!!errors.checkboxRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.checkboxRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="checkboxDesign">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Desain Tampilan Bagian
                  </Form.Label>
                  <Form.Select
                    value={checkboxDesign}
                    onChange={e => setCheckboxDesign(e.target.value)}
                  >
                    <option value="Box">Box</option>
                    <option value="Non-Box">Non-Box</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="checkboxLayout">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Urutan Penampilan Bagian
                  </Form.Label>
                  <Form.Select
                    value={checkboxLayout}
                    onChange={e => setCheckboxLayout(e.target.value)}
                  >
                    <option value="Horizontal">Horizontal</option>
                    <option value="Vertical">Vertical</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                <h5 className="mb-2">
                  Pilihan<span className="text-danger">*</span>
                </h5>
              </Form.Label>
              <div
                className={`${errors.checkboxOptions ? 'border-danger' : ''}`}
              >
                <div className="d-flex mb-2">
                  <div style={{ width: '80px' }}>Default</div>
                  <div className="flex-grow-1">Isi Pilihan</div>
                </div>
                {checkboxOptions.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex mb-2 align-items-center gap-2"
                  >
                    <div style={{ width: '80px' }}>
                      <Form.Check
                        type="checkbox"
                        checked={option.isDefault}
                        onChange={() => handleToggleCheckboxDefault(index)}
                        className="ms-3"
                      />
                    </div>
                    <Form.Control
                      type="text"
                      placeholder="Masukan pilihan"
                      value={option.value}
                      onChange={e => {
                        const newOptions = [...checkboxOptions];
                        newOptions[index].value = e.target.value;
                        setCheckboxOptions(newOptions);
                      }}
                      className="flex-grow-1"
                    />
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleRemoveCheckboxOption(index)}
                      disabled={checkboxOptions.length <= 1}
                    >
                      -
                    </Button>
                  </div>
                ))}
                <div className="mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={handleAddCheckboxOption}
                    className="w-100"
                  >
                    + Tambah Pilihan
                  </Button>
                </div>
                {errors.checkboxOptions && (
                  <div className="text-danger mt-1 small">
                    {errors.checkboxOptions}
                  </div>
                )}
              </div>
            </Form.Group>
          </>
        );

      case 'Dropdown list':
        return (
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="dropdownLabel">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Label<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan pilihan"
                    value={dropdownLabel}
                    onChange={e => setDropdownLabel(e.target.value)}
                    isInvalid={!!errors.dropdownLabel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dropdownLabel}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="dropdownRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={dropdownRequired}
                    onChange={e => setDropdownRequired(e.target.value)}
                    isInvalid={!!errors.dropdownRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.dropdownRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                <h5 className="mb-2">
                  Pilihan<span className="text-danger">*</span>
                </h5>
              </Form.Label>
              <div
                className={`${errors.dropdownOptions ? 'border-danger' : ''}`}
              >
                <div className="d-flex mb-2">
                  <div style={{ width: '80px' }}>Default</div>
                  <div className="flex-grow-1">Isi Pilihan</div>
                </div>
                {dropdownOptions.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex mb-2 align-items-center gap-2"
                  >
                    <div style={{ width: '80px' }}>
                      <Form.Check
                        type="checkbox"
                        checked={option.isDefault}
                        onChange={() => handleSetDropdownDefault(index)}
                        className="ms-3 custom-checkbox"
                        style={
                          {
                            '--bs-form-check-bg': option.isDefault
                              ? '#fff'
                              : '#000'
                          } as React.CSSProperties
                        }
                      />
                    </div>
                    <Form.Control
                      type="text"
                      placeholder="Masukan pilihan"
                      value={option.value}
                      onChange={e => {
                        const newOptions = [...dropdownOptions];
                        newOptions[index].value = e.target.value;
                        setDropdownOptions(newOptions);
                      }}
                      className="flex-grow-1"
                    />
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleRemoveDropdownOption(index)}
                      disabled={dropdownOptions.length <= 1}
                    >
                      -
                    </Button>
                  </div>
                ))}
                <div className="mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={handleAddDropdownOption}
                    className="w-100"
                  >
                    + Tambah Pilihan
                  </Button>
                </div>
                {errors.dropdownOptions && (
                  <div className="text-danger mt-1 small">
                    {errors.dropdownOptions}
                  </div>
                )}
              </div>
            </Form.Group>
          </>
        );

      case 'Multi select list':
        return (
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="multiSelectLabel">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Label<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan pilihan"
                    value={multiSelectLabel}
                    onChange={e => setMultiSelectLabel(e.target.value)}
                    isInvalid={!!errors.multiSelectLabel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.multiSelectLabel}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="multiSelectRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    value={multiSelectRequired}
                    onChange={e => setMultiSelectRequired(e.target.value)}
                    isInvalid={!!errors.multiSelectRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.multiSelectRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="multiSelectDesign">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Desain Tampilan Bagian
                  </Form.Label>
                  <Form.Select
                    value={checkboxDesign}
                    onChange={e => setCheckboxDesign(e.target.value)}
                  >
                    <option value="Box">Box</option>
                    <option value="Non-Box">Non-Box</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="multiSelectLayout">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Urutan Penampilan Bagian
                  </Form.Label>
                  <Form.Select
                    value={checkboxLayout}
                    onChange={e => setCheckboxLayout(e.target.value)}
                  >
                    <option value="Horizontal">Horizontal</option>
                    <option value="Vertical">Vertical</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                <h5 className="mb-2">
                  Pilihan<span className="text-danger">*</span>
                </h5>
              </Form.Label>
              <div
                className={`${
                  errors.multiSelectOptions ? 'border-danger' : ''
                }`}
              >
                <div className="d-flex mb-2">
                  <div style={{ width: '80px' }}>Default</div>
                  <div className="flex-grow-1">Isi Pilihan</div>
                </div>
                {multiSelectOptions.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex mb-2 align-items-center gap-2"
                  >
                    <div style={{ width: '80px' }}>
                      <Form.Check
                        type="checkbox"
                        checked={option.isDefault}
                        onChange={() => handleToggleMultiSelectDefault(index)}
                        className="ms-3"
                      />
                    </div>
                    <Form.Control
                      type="text"
                      placeholder="Masukan pilihan"
                      value={option.value}
                      onChange={e => {
                        const newOptions = [...multiSelectOptions];
                        newOptions[index].value = e.target.value;
                        setMultiSelectOptions(newOptions);
                      }}
                      className="flex-grow-1"
                    />
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleRemoveMultiSelectOption(index)}
                      disabled={multiSelectOptions.length <= 1}
                    >
                      -
                    </Button>
                  </div>
                ))}
                <div className="mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={handleAddMultiSelectOption}
                    className="w-100"
                  >
                    + Tambah Pilihan
                  </Button>
                </div>
                {errors.multiSelectOptions && (
                  <div className="text-danger mt-1 small">
                    {errors.multiSelectOptions}
                  </div>
                )}
              </div>
            </Form.Group>
          </>
        );

      case 'Date':
        return (
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="dateLabel">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Label<span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Masukan label"
                    value={dateLabel}
                    onChange={e => setDateLabel(e.target.value)}
                    isInvalid={!!errors.dateLabel}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dateLabel}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="dateRequired">
                  <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                    Sifat Bagian
                  </Form.Label>
                  <Form.Select
                    value={dateRequired}
                    onChange={e => setDateRequired(e.target.value)}
                    isInvalid={!!errors.dateRequired}
                  >
                    <option value="">Pilih sifat bagian</option>
                    <option value="Wajib isi">Wajib isi</option>
                    <option value="Opsional">Opsional</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.dateRequired}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  // Definisi tipe untuk field types
  type FieldType =
    | 'Single line text'
    | 'Multiple line text'
    | 'Radio buttons'
    | 'Checkboxes'
    | 'Dropdown list'
    | 'Multi select list'
    | 'Date';

  // Tambahkan mutation hook
  const [addTemplateField, { isLoading }] =
    useAddCustomerTemplateFieldMutation();

  // Tambahkan mutation hook untuk edit
  const [editTemplateField] = useEditCustomerTemplateFieldMutation();

  // Modifikasi useEffect untuk menyimpan ID opsi saat mode edit
  useEffect(() => {
    if (isEdit && editData) {
      setTemplateName(editData.fieldName);
      setGroupType(
        editData.fieldGroup === 'merchant'
          ? 'Bagian Pedagang'
          : 'Bagian Pelanggan'
      );

      // Set field type berdasarkan fieldType.code dari API
      const fieldTypeMap: { [key: string]: string } = {
        SINGLE_LINE_TEXT: 'Single line text',
        MULTIPLE_LINE_TEXT: 'Multiple line text',
        RADIO_BUTTONS: 'Radio buttons',
        CHECKBOXES: 'Checkboxes',
        DROPDOWN_LIST: 'Dropdown list',
        MULTI_SELECT_LIST: 'Multi select list',
        DATE: 'Date'
      };

      const currentFieldType =
        fieldTypeMap[editData.fieldType.code] || 'Single line text';
      setFieldType(currentFieldType);

      // Set label dan required berdasarkan tipe field
      const isRequiredValue = editData.isRequired ? 'Wajib isi' : 'Opsional';

      switch (currentFieldType) {
        case 'Single line text':
          setSingleLineLabel(editData.label || '');
          setSingleLineRequired(isRequiredValue);
          setSingleLineMaxLength(editData.fieldTextLength?.toString() || '');
          break;
        case 'Multiple line text':
          setMultiLineLabel(editData.label || '');
          setMultiLineRequired(isRequiredValue);
          setMultiLineMaxLength(editData.fieldTextLength?.toString() || '');
          break;
        case 'Radio buttons':
          setRadioLabel(editData.label || '');
          setRadioRequired(isRequiredValue);
          if (editData.customerTemplateFieldOption) {
            setRadioOptions(
              editData.customerTemplateFieldOption.map(opt => ({
                id: opt.id,
                value: opt.optionValue,
                isDefault: opt.selectedChoice
              }))
            );
          }
          break;
        case 'Checkboxes':
          setCheckboxLabel(editData.label || '');
          setCheckboxRequired(isRequiredValue);
          if (editData.customerTemplateFieldOption) {
            setCheckboxOptions(
              editData.customerTemplateFieldOption.map(opt => ({
                id: opt.id,
                value: opt.optionValue,
                isDefault: opt.selectedChoice
              }))
            );
          }
          break;
        case 'Dropdown list':
          setDropdownLabel(editData.label || '');
          setDropdownRequired(isRequiredValue);
          if (editData.customerTemplateFieldOption) {
            setDropdownOptions(
              editData.customerTemplateFieldOption.map(opt => ({
                id: opt.id,
                value: opt.optionValue,
                isDefault: opt.selectedChoice
              }))
            );
          }
          break;
        case 'Multi select list':
          setMultiSelectLabel(editData.label || '');
          setMultiSelectRequired(isRequiredValue);
          if (editData.customerTemplateFieldOption) {
            setMultiSelectOptions(
              editData.customerTemplateFieldOption.map(opt => ({
                id: opt.id,
                value: opt.optionValue,
                isDefault: opt.selectedChoice
              }))
            );
          }
          break;
        case 'Date':
          setDateLabel(editData.label || '');
          setDateRequired(isRequiredValue);
          break;
      }
    }
  }, [isEdit, editData]);

  // Tambahkan fungsi resetForm untuk membersihkan form
  const resetForm = () => {
    setTemplateName('');
    setFieldType('Single line text');
    setSingleLineLabel('');
    setSingleLineRequired('');
    setSingleLineDisplaySize('Medium');
    setSingleLineMaxLength('128');
    setMultiLineLabel('');
    setMultiLineRequired('');
    setMultiLineDisplaySize('Medium');
    setMultiLineMaxLength('1000');
    setMultiLineRows('3');
    setRadioLabel('');
    setRadioRequired('');
    setRadioOptions([
      { value: '', isDefault: true },
      { value: '', isDefault: false }
    ]);
    // ... reset state lainnya
    setErrors({});
  };

  // Fungsi helper untuk mendapatkan label berdasarkan tipe field
  const getFieldLabel = () => {
    switch (fieldType) {
      case 'Single line text':
        return singleLineLabel;
      case 'Multiple line text':
        return multiLineLabel;
      case 'Radio buttons':
        return radioLabel;
      case 'Checkboxes':
        return checkboxLabel;
      case 'Dropdown list':
        return dropdownLabel;
      case 'Multi select list':
        return multiSelectLabel;
      case 'Date':
        return dateLabel;
      default:
        return '';
    }
  };

  // Fungsi helper untuk mendapatkan field size
  const getFieldSize = () => {
    switch (fieldType) {
      case 'Single line text':
        return singleLineDisplaySize;
      case 'Multiple line text':
        return multiLineDisplaySize;
      default:
        return 'Medium';
    }
  };

  // Fungsi helper untuk mendapatkan text length
  const getFieldTextLength = () => {
    switch (fieldType) {
      case 'Single line text':
        return parseInt(singleLineMaxLength);
      case 'Multiple line text':
        return parseInt(multiLineMaxLength);
      default:
        return 255;
    }
  };

  // Modifikasi fungsi checkForChanges
  const checkForChanges = () => {
    if (isEdit && editData) {
      // Dapatkan nilai required dari data yang sedang diedit
      const currentRequired = (() => {
        switch (fieldType) {
          case 'Single line text':
            return singleLineRequired;
          case 'Multiple line text':
            return multiLineRequired;
          case 'Radio buttons':
            return radioRequired;
          case 'Checkboxes':
            return checkboxRequired;
          case 'Dropdown list':
            return dropdownRequired;
          case 'Multi select list':
            return multiSelectRequired;
          case 'Date':
            return dateRequired;
          default:
            return '';
        }
      })();

      // Bandingkan nilai saat ini dengan data awal
      const hasChanges =
        templateName !== editData.fieldName ||
        getFieldLabel() !== editData.label ||
        groupType.toLowerCase() !== editData.fieldGroup ||
        (currentRequired === 'Wajib isi') !== editData.isRequired;

      // Tambahkan pengecekan untuk options jika tipe field memiliki options
      if (
        [
          'Radio buttons',
          'Checkboxes',
          'Dropdown list',
          'Multi select list'
        ].includes(fieldType)
      ) {
        const originalOptions = editData.customerTemplateFieldOption || [];
        const currentOptions = (() => {
          switch (fieldType) {
            case 'Radio buttons':
              return radioOptions;
            case 'Checkboxes':
              return checkboxOptions;
            case 'Dropdown list':
              return dropdownOptions;
            case 'Multi select list':
              return multiSelectOptions;
            default:
              return [];
          }
        })();

        // Bandingkan jumlah options
        if (originalOptions.length !== currentOptions.length) {
          return true;
        }

        // Bandingkan nilai dan status default setiap option
        const optionsChanged = currentOptions.some((option, index) => {
          const originalOption = originalOptions[index];
          return (
            option.value !== originalOption.optionValue ||
            option.isDefault !== originalOption.selectedChoice
          );
        });

        return hasChanges || optionsChanged;
      }

      return hasChanges;
    }

    // Jika bukan mode edit, cek apakah form telah diisi
    return (
      templateName.trim() !== '' ||
      singleLineLabel.trim() !== '' ||
      multiLineLabel.trim() !== '' ||
      radioLabel.trim() !== '' ||
      checkboxLabel.trim() !== '' ||
      dropdownLabel.trim() !== '' ||
      multiSelectLabel.trim() !== '' ||
      dateLabel.trim() !== ''
    );
  };

  // Modifikasi handleCloseWithConfirmation
  const handleCloseWithConfirmation = () => {
    const hasChanges = checkForChanges();

    if (hasChanges) {
      showCustomAlert({
        icon: 'warning',
        label: 'Bagian tidak akan disimpan',
        deskripsi: 'Apakah Anda yakin untuk membatalkan proses ini?',
        buttonType: 'yes-no',
        onConfirm: () => {
          resetForm();
          handleClose();
        }
      });
    } else {
      resetForm();
      handleClose();
    }
  };

  // Modifikasi handleSubmit untuk menyertakan id opsi saat edit
  const handleSubmit = async () => {
    const trimmedName = templateName.trim();
    const currentFieldGroup =
      groupType.toLowerCase() === 'bagian pedagang' ? 'merchant' : 'customer';

    // Modifikasi pengecekan duplikat untuk mengecualikan data yang sedang diedit
    const isDuplicate =
      currentFieldGroup === 'merchant'
        ? templateData?.data?.merchant?.some(
            (field: CustomerTemplateFieldDetail) =>
              field.fieldName.toLowerCase() === trimmedName.toLowerCase() &&
              (!isEdit || field.id !== editData?.id)
          )
        : templateData?.data?.customer?.some(
            (field: CustomerTemplateFieldDetail) =>
              field.fieldName.toLowerCase() === trimmedName.toLowerCase() &&
              (!isEdit || field.id !== editData?.id)
          );

    if (isDuplicate) {
      showCustomAlert({
        icon: 'warning',
        label: 'Terdapat nama bagian yang sama',
        deskripsi: `Mohon ubah nama bagian yang akan Anda ${
          isEdit ? 'edit' : 'tambahkan'
        } di ${groupType}`,
        buttonType: 'ok'
      });
      return;
    }

    if (!validateForm()) {
      return;
    }

    showCustomAlert({
      icon: 'question',
      label: `Bagian akan ${isEdit ? 'diperbarui' : 'disimpan'}`,
      deskripsi: `Apakah Anda yakin data bagian sudah benar?`,
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          let fieldTypeId: number;
          let options;
          let isRequired = false;

          // Tentukan required berdasarkan tipe field
          switch (fieldType) {
            case 'Single line text':
              isRequired = singleLineRequired === 'Wajib isi';
              break;
            case 'Multiple line text':
              isRequired = multiLineRequired === 'Wajib isi';
              break;
            case 'Radio buttons':
              isRequired = radioRequired === 'Wajib isi';
              break;
            case 'Checkboxes':
              isRequired = checkboxRequired === 'Wajib isi';
              break;
            case 'Dropdown list':
              isRequired = dropdownRequired === 'Wajib isi';
              break;
            case 'Multi select list':
              isRequired = multiSelectRequired === 'Wajib isi';
              break;
            case 'Date':
              isRequired = dateRequired === 'Wajib isi';
              break;
          }

          // Modifikasi switch case untuk memperbaiki field type ID
          switch (fieldType) {
            case 'Single line text':
              fieldTypeId = 22; // ID untuk Single line text
              break;
            case 'Multiple line text':
              fieldTypeId = 23; // ID untuk Multiple line text
              break;
            case 'Radio buttons':
              fieldTypeId = 24;
              options = radioOptions
                .filter(opt => opt.value.trim() !== '')
                .map(opt => ({
                  ...(opt.id && { id: opt.id }),
                  optionValue: opt.value,
                  selectedChoice: opt.isDefault
                }));
              break;
            case 'Checkboxes':
              fieldTypeId = 25;
              options = checkboxOptions
                .filter(opt => opt.value.trim() !== '')
                .map(opt => ({
                  ...(opt.id && { id: opt.id }),
                  optionValue: opt.value,
                  selectedChoice: opt.isDefault
                }));
              break;
            case 'Dropdown list':
              fieldTypeId = 26;
              options = dropdownOptions
                .filter(opt => opt.value.trim() !== '')
                .map(opt => ({
                  ...(opt.id && { id: opt.id }),
                  optionValue: opt.value,
                  selectedChoice: opt.isDefault
                }));
              break;
            case 'Multi select list':
              fieldTypeId = 27;
              options = multiSelectOptions
                .filter(opt => opt.value.trim() !== '')
                .map(opt => ({
                  ...(opt.id && { id: opt.id }),
                  optionValue: opt.value,
                  selectedChoice: opt.isDefault
                }));
              break;
            case 'Date':
              fieldTypeId = 28;
              break;
            default:
              fieldTypeId = 22; // Default ke Single line text
          }

          const payload: AddTemplateFieldRequest = {
            customerTemplateId: templateId,
            fieldName: trimmedName,
            fieldType: fieldTypeId,
            fieldGroup:
              groupType.toLowerCase() === 'bagian pedagang'
                ? 'merchant'
                : 'customer',
            isRequired: isRequired,
            label: getFieldLabel(),
            fieldSize: getFieldSize()?.toLowerCase() || 'medium',
            fieldTextLength: getFieldTextLength() || 255,
            customerTemplateFieldOption: options || []
          };

          let response;
          if (isEdit && editData) {
            response = await editTemplateField({
              id: editData.id,
              data: payload
            }).unwrap();
          } else {
            response = await addTemplateField(payload).unwrap();
          }

          if (response) {
            // Tutup modal terlebih dahulu
            handleClose();
            resetForm();

            // Panggil onSuccess jika ada
            if (onSuccess) {
              onSuccess();
            }

            // Tampilkan alert sukses setelah modal tertutup
            showCustomAlert({
              icon: 'success',
              label: 'Berhasil',
              deskripsi: `Bagian berhasil ${
                isEdit ? 'diperbarui' : 'ditambahkan'
              }`,
              buttonType: 'ok'
            });
          }
        } catch (error) {
          console.error('Failed to handle field:', error);
          showCustomAlert({
            icon: 'error',
            label: 'Gagal',
            deskripsi:
              error instanceof Error
                ? error.message
                : `Gagal ${isEdit ? 'memperbarui' : 'menambahkan'} bagian baru`,
            buttonType: 'ok'
          });
        }
      }
    });
  };

  // Modifikasi judul modal
  const modalTitle = isEdit ? 'Edit Bagian' : 'Tambah Bagian';

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      size="lg"
      show={show}
      onHide={handleCloseWithConfirmation}
      centered
    >
      <Modal.Header className="px-5 py-4">
        <Modal.Title className="me-auto fw-bolder">{modalTitle}</Modal.Title>
        <div className="d-flex">
          <Button
            variant="link"
            className="text-danger"
            onClick={handleCloseWithConfirmation}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Menyimpan...' : 'Simpan'}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-5 py-4">
        <Form>
          <Row className="mb-3">
            <h5 className="mb-2">Informasi Bagian</h5>
            <Col md={4}>
              <Form.Group controlId="templateName" className="mb-3">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Nama Bagian<span className="text-danger"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukan nama bagian"
                  value={templateName}
                  onChange={e => {
                    setTemplateName(e.target.value);
                    setSingleLineLabel(e.target.value);
                    setMultiLineLabel(e.target.value);
                    setRadioLabel(e.target.value);
                    setCheckboxLabel(e.target.value);
                    setDropdownLabel(e.target.value);
                    setMultiSelectLabel(e.target.value);
                    setDateLabel(e.target.value);
                  }}
                  isInvalid={!!errors.templateName}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.templateName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="fieldType" className="mb-3">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Jenis Bagian
                </Form.Label>
                <Form.Select
                  value={fieldType}
                  onChange={e => setFieldType(e.target.value)}
                >
                  <option value="Single line text">Single line text</option>
                  <option value="Multiple line text">Multiple line text</option>
                  <option value="Radio buttons">Radio buttons</option>
                  <option value="Checkboxes">Checkboxes</option>
                  <option value="Dropdown list">Dropdown list</option>
                  <option value="Multi select list">Multi select list</option>
                  <option value="Date">Date</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="groupType" className="mb-3">
                <Form.Label className="p-0 fw-bolder fs-9 text-capitalize">
                  Kelompok Bagian
                </Form.Label>
                <Form.Select
                  value={groupType}
                  onChange={e => setGroupType(e.target.value)}
                >
                  <option value="Bagian Pelanggan">Bagian Pelanggan</option>
                  <option value="Bagian Pedagang">Bagian Pedagang</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <h5 className="mb-2">Tampilan</h5>
          {renderDisplaySection()}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTemplateField;
