// CheckboxItem.tsx
import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

export interface CheckboxItemProps {
  type?: 'checkbox' | 'radio';
  name: string;
  label: string | ReactNode;
  value: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelClassName?: string; // Tambahkan prop untuk kelas label
  checked?: boolean; // Tambahkan prop checked
}

const CheckboxItem = ({
  type = 'checkbox',
  name,
  label,
  value,
  onChange,
  labelClassName, // Ambil prop labelClassName
  checked // Ambil prop checked
}: CheckboxItemProps) => {
  return (
    <Form.Check
      type={type}
      id={String(value)}
      className="mb-0 d-flex align-items-center gap-2"
    >
      <Form.Check.Input
        type={type}
        value={value}
        name={name}
        className="mt-0"
        onChange={onChange}
        checked={checked} // Gunakan prop checked
      />
      <Form.Check.Label
        className={`d-block lh-sm fs-8 fw-normal mb-0 ${labelClassName}`}
      >
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default CheckboxItem;
