import {
  faFileExport,
  faFloppyDisk,
  faRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import CustomerNotesCard from 'components/cards/CustomerNotesCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';

import { Card, Col, Row } from 'react-bootstrap';

import CustomerPenjualanTable from 'components/tables/CustomerPenjualanTable';

const Pengaturan = () => {
  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Pengaturan Pelanggan</h2>
        </Col>
        <Col xs="auto" className="d-flex gap-3 flex-wrap p-0">
          <Button variant="link" className="text-body px-0">
            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
            Unduh Data
          </Button>
          <Button variant="outline-danger">
            <FontAwesomeIcon icon={faRotateLeft} className="me-2" />
            Kembali Default
          </Button>
          <Button variant="primary">
            <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
            Simpan Perubahan
          </Button>
        </Col>
      </Row>

      <Row className="g-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          <Row className="g-3">
            <Card>
              <Card.Body>
                <Card.Title as="h4">Informasi Wajib</Card.Title>
                <Row>
                  <Col xl={6}>
                    <h4>Tes</h4>
                  </Col>
                  <Col xl={6}>
                    <h4>Tes</h4>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <CustomerNotesCard className="h-100" />
          </Row>
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
          <Row className="d-flex flex-column g-3">
            <CustomerPenjualanTable />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Pengaturan;
