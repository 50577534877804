import { faPlus, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Button from 'components/base/Button';
import BagianPedagangCard from 'components/cards/BagianPedagangCard';
import BagianPelangganCard from 'components/cards/BagianPelangganCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AddTemplateField from 'components/modals/AddTemplateFieldModal';
import { detailFormTemplatBreadcrumbItems } from 'data/commonData';
import { ChangeEvent, useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Card, Button } from 'react-bootstrap';
import {
  useGetCustomerTemplateByIdQuery,
  useDeleteCustomerTemplateMutation,
  useEditCustomerTemplateMutation,
  useGetCustomerTemplateQuery
} from 'api/customerTemplateApi';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { showCustomAlert } from 'helpers/showCustomAlert';
import { CustomerTemplateFieldDetail } from 'api/response/CustomerTemplateResponse';
import { CustomerTemplateDetailResponse } from 'api/response/CustomerTemplateResponse';

interface TemplateData {
  templateName: string;
  formType: string;
  status: boolean;
  merchant: CustomerTemplateFieldDetail[];
  customer: CustomerTemplateFieldDetail[];
}

// Tambahkan konstanta untuk opsi form type
const FORM_TYPE_OPTIONS = ['Customizeable', 'Template A', 'Template B'];

const CustomerTemplateDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: templateResponse,
    refetch,
    isLoading
  } = useGetCustomerTemplateByIdQuery(Number(id)) as {
    data?: CustomerTemplateDetailResponse;
    refetch: () => void;
    isLoading: boolean;
  };

  const [templateData, setTemplateData] = useState<TemplateData>({
    templateName: '',
    formType: '',
    status: true,
    merchant: [],
    customer: []
  });

  const [templateNameError, setTemplateNameError] = useState<string>('');

  // Mengupdate state ketika data dari API sudah tersedia
  useEffect(() => {
    if (templateResponse?.data) {
      setTemplateData({
        templateName: templateResponse.data.templateName,
        formType: templateResponse.data.formType,
        status: templateResponse.data.status,
        merchant: templateResponse.data.merchant || [],
        customer: templateResponse.data.customer || []
      });
    }
  }, [templateResponse]);

  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalGroupType, setModalGroupType] = useState('');

  const navigate = useNavigate();
  const [deleteCustomerTemplate] = useDeleteCustomerTemplateMutation();
  const [editCustomerTemplate] = useEditCustomerTemplateMutation();

  const { data: allTemplates } = useGetCustomerTemplateQuery({});

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTemplateData(prevData => ({
      ...prevData,
      [name]: name === 'status' ? value === 'true' : value
    }));

    // Reset error saat user mengetik di field nama template
    if (name === 'templateName') {
      setTemplateNameError('');
    }
  };

  const handleSave = () => {
    // Validasi nama template
    if (!templateData.templateName.trim()) {
      setTemplateNameError('Nama template harus diisi');
      return;
    }

    // Cek apakah nama template sudah digunakan (dengan trim)
    const isDuplicate = allTemplates?.data?.result.some(
      template =>
        template.templateName.trim() === templateData.templateName.trim() &&
        template.id !== id
    );

    if (isDuplicate) {
      showCustomAlert({
        icon: 'warning',
        label: 'Terdapat nama bagian yang sama',
        deskripsi: 'Mohon ubah nama bagian yang akan Anda tambahkan',
        buttonType: 'ok'
      });
      return;
    }

    showCustomAlert({
      icon: 'warning',
      label: 'Konfirmasi Perubahan',
      deskripsi: 'Apakah Anda yakin ingin menyimpan perubahan ini?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          const response = await editCustomerTemplate({
            id: id as string,
            data: {
              templateName: templateData.templateName.trim(),
              formType: templateData.formType,
              status: templateData.status
            }
          }).unwrap();

          if (response.code === 200) {
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Template berhasil diperbarui',
              buttonType: 'ok',
              onConfirm: () => {
                setIsEditing(false);
                refetch();
              }
            });
          }
        } catch (error) {
          console.error('Error updating template:', error);
          toast.error(
            error instanceof Error
              ? error.message
              : 'Gagal memperbarui template'
          );
        }
      }
    });
  };

  const handleCancel = () => {
    // Cek apakah ada perubahan yang dilakukan
    const hasChanges =
      templateResponse?.data &&
      (templateData.templateName !== templateResponse.data.templateName ||
        templateData.formType !== templateResponse.data.formType ||
        templateData.status !== templateResponse.data.status);

    if (hasChanges) {
      showCustomAlert({
        icon: 'warning',
        label: 'Batalkan Perubahan',
        deskripsi: 'Perubahan yang Anda lakukan akan hilang. Lanjutkan?',
        buttonType: 'yes-no',
        onConfirm: () => {
          // Reset data ke nilai sebelumnya
          if (templateResponse?.data) {
            setTemplateData({
              templateName: templateResponse.data.templateName,
              formType: templateResponse.data.formType,
              status: templateResponse.data.status,
              merchant: templateResponse.data.merchant || [],
              customer: templateResponse.data.customer || []
            });
          }
          setIsEditing(false);
        }
      });
    } else {
      // Jika tidak ada perubahan, langsung tutup mode edit
      setIsEditing(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleTambahBagian = (groupType: string) => {
    setModalGroupType(groupType);
    setShowModal(true);
  };

  const handleDeleteTemplate = async () => {
    showCustomAlert({
      icon: 'warning',
      label: 'Form templat akan dihapus',
      deskripsi: 'Apakah Anda yakin untuk menghapus form templat?',
      buttonType: 'yes-no',
      onConfirm: async () => {
        try {
          // Pastikan id tidak undefined sebelum memanggil deleteCustomerTemplate
          if (!id) {
            throw new Error('ID template tidak ditemukan');
          }
          const response = await deleteCustomerTemplate(id).unwrap();
          if (response.code === 200) {
            // Tampilkan alert sukses
            showCustomAlert({
              icon: 'success',
              label: 'Sukses!',
              deskripsi: 'Form templat berhasil dihapus.',
              buttonType: 'ok',
              onConfirm: () => {
                navigate('/apps/e-commerce/admin/customer/templat', {
                  state: {
                    refresh: true,
                    timestamp: new Date().getTime()
                  }
                });
              }
            });
          } else {
            throw new Error(response.message || 'Gagal menghapus template');
          }
        } catch (error) {
          console.error('Error deleting template:', error);
          toast.error(
            error instanceof Error ? error.message : 'Gagal menghapus template'
          );
        }
      }
    });
  };

  return (
    <div>
      <PageBreadcrumb items={detailFormTemplatBreadcrumbItems} />

      <div className="mb-9">
        {isLoading ? (
          // Tampilan loading
          <div className="text-center py-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="mt-2">Memuat data template...</p>
          </div>
        ) : (
          // Konten utama
          <>
            <div className="mb-3">
              <div className="d-flex justify-content-between">
                <h2 className="mt-1">Detail Form Templat</h2>

                <div className="ms-auto d-flex align-items-center gap-3 flex-wrap">
                  <Button
                    variant="outline-danger"
                    onClick={handleDeleteTemplate}
                  >
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                    Hapus Template
                  </Button>

                  <Button variant="primary" onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Tambah Bagian
                  </Button>
                </div>
              </div>
            </div>

            <Card className="w-100">
              <Card.Body>
                <Row className="mb-4">
                  <Col xs={12} className="d-flex align-items-center">
                    <h3>{templateData.templateName}</h3>
                    {!isEditing && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={handleEditClick}
                      >
                        <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                      </Button>
                    )}
                  </Col>
                </Row>

                {!isEditing ? (
                  // Mode Display
                  <Row className="mb-3">
                    <Col xs={12} md={4} className="d-flex flex-column pe-3">
                      <span className="fw-bold text-muted">Nama Templat</span>
                      <span className="fw-normal">
                        {templateData.templateName || '-'}
                      </span>
                    </Col>
                    <Col xs={12} md={4} className="d-flex flex-column pe-3">
                      <span className="fw-bold text-muted">Tipe Form</span>
                      <span className="fw-normal">
                        {templateData.formType || '-'}
                      </span>
                    </Col>
                    <Col xs={12} md={4} className="d-flex flex-column pe-3">
                      <span className="fw-bold text-muted">Status</span>
                      <span className="fw-normal">
                        <span
                          className={`badge bg-${
                            templateData.status ? 'success' : 'danger'
                          }`}
                        >
                          {templateData.status ? 'Aktif' : 'Nonaktif'}
                        </span>
                      </span>
                    </Col>
                  </Row>
                ) : (
                  // Mode Edit
                  <Form>
                    <Row className="mb-3">
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold p-0 fs-9 text-capitalize">
                            Nama Templat <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="templateName"
                            value={templateData.templateName}
                            onChange={handleInputChange}
                            isInvalid={!!templateNameError}
                          />
                          <Form.Control.Feedback type="invalid">
                            {templateNameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold p-0 fs-9 text-capitalize">
                            Tipe Form
                          </Form.Label>
                          <Form.Select
                            name="formType"
                            value={templateData.formType}
                            onChange={handleInputChange}
                          >
                            <option value="">Pilih Tipe Form</option>
                            {FORM_TYPE_OPTIONS.map(type => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold p-0 fs-9 text-capitalize">
                            Status
                          </Form.Label>
                          <Form.Select
                            name="status"
                            value={String(templateData.status)}
                            onChange={handleInputChange}
                          >
                            <option value="true">Aktif</option>
                            <option value="false">Nonaktif</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="d-flex gap-2 justify-content-end">
                      <Button variant="secondary" onClick={handleCancel}>
                        Batal
                      </Button>
                      <Button variant="primary" onClick={handleSave}>
                        Simpan
                      </Button>
                    </div>
                  </Form>
                )}
              </Card.Body>
            </Card>

            <Row className="mt-3">
              <Col md={6}>
                <BagianPedagangCard
                  onTambahBagian={() => handleTambahBagian('Bagian Pedagang')}
                  fields={templateData.merchant}
                  onRefetch={refetch}
                  templateResponse={templateResponse}
                />
              </Col>
              <Col md={6}>
                <BagianPelangganCard
                  onTambahBagian={() => handleTambahBagian('Bagian Pelanggan')}
                  fields={templateData.customer}
                  onRefetch={refetch}
                  templateResponse={templateResponse}
                />
              </Col>
            </Row>
          </>
        )}
      </div>

      {/* Modal Component */}
      <AddTemplateField
        show={showModal}
        handleClose={() => setShowModal(false)}
        templateId={Number(id)}
        defaultGroupType={modalGroupType}
        onSuccess={() => {
          refetch();
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default CustomerTemplateDetails;
