import { useIntrospectMutation } from 'api/authApi';
import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const [introspect] = useIntrospectMutation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      toast.error('Session Expired, Please Login Again');
      navigate('/login', { replace: true });
      return;
    }

    introspect({ token })
      .unwrap()
      .catch(e => {
        console.log(e);
        navigate('/login', { replace: true });
      });
  }, [introspect, navigate, location.pathname]);

  return children;
};

export const RedirectHomeIfLoggedIn = ({
  children
}: {
  children: ReactNode;
}) => {
  const [introspect] = useIntrospectMutation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      introspect({ token })
        .unwrap()
        .then(() => {
          navigate('/', { replace: true });
        });
    }
  }, [introspect, navigate, location.pathname]);

  return children;
};
