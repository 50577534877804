import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserLogin, useUserLoginMutation } from 'api/authApi';
import { BaseResponse } from 'api/response/BaseResponse';
import Button from 'components/base/Button';
import { isEmpty } from 'helpers/utils';
import { RefObject, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

interface IFormSuccessState {
  emailValid: boolean;
  passwordValid: boolean;
}

const SignInForm = () => {
  const emailref: RefObject<HTMLInputElement> = useRef(null);
  const passwordRef: RefObject<HTMLInputElement> = useRef(null);
  const [validated, setValidated] = useState<IFormSuccessState>({
    emailValid: false,
    passwordValid: false
  });
  const [userLogin] = useUserLoginMutation();

  const [submitable, setSubmitAble] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated({
        emailValid: true,
        passwordValid: false
      });
      return;
    }

    const email = emailref.current?.value;
    const password = passwordRef.current?.value;

    if (isEmpty(email)) {
      setValidated({
        ...validated,
        emailValid: true
      });
      return;
    }
    if (isEmpty(password)) {
      setValidated({
        ...validated,
        passwordValid: true
      });
      return;
    }

    const body: IUserLogin = {
      email: email as string,
      password: password as string
    };

    try {
      setSubmitAble(!submitable);
      const toastLoad = toast.loading('Signing You In');
      const response = await userLogin(body);
      if (response.error) {
        if ('data' in response.error) {
          setValidated({
            emailValid: true,
            passwordValid: true
          });
          const errorData: BaseResponse = response.error.data as BaseResponse;
          toast.update(toastLoad, {
            render: errorData.message || 'Internal Server Error',
            type: 'error',
            isLoading: false,
            autoClose: 1000
          });
          return;
        } else {
          console.error('login error: ', response.error);
          toast.update(toastLoad, {
            render: 'Internal Server Error',
            type: 'error',
            isLoading: false,
            autoClose: 1000
          });
          return;
        }
      }

      const data = response.data;
      toast.update(toastLoad, {
        render: 'Success Sign-In',
        type: 'success',
        isLoading: false,
        autoClose: 1000
      });

      localStorage.setItem('accessToken', data?.data.token as string);
      localStorage.setItem('refreshToken', data?.data.refresh as string);

      navigate('/');
    } catch (error) {
      console.error('login error: ', error);
      toast.error('Internal Server Error');
    } finally {
      setSubmitAble(true);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign In</h3>
        <p className="text-body-tertiary">Get access to your account</p>
      </div>
      <Form
        noValidate
        validated={validated.emailValid || validated.passwordValid}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              className="form-icon-input"
              placeholder="name@example.com"
              ref={emailref}
              required
              isInvalid={
                validated.emailValid && isEmpty(emailref.current?.value)
              }
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Password</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type="password"
              className="form-icon-input"
              placeholder="Password"
              ref={passwordRef}
              required
              isInvalid={
                validated.passwordValid && isEmpty(passwordRef.current?.value)
              }
            />
            <FontAwesomeIcon
              icon={faKey}
              className="text-body fs-9 form-icon"
            />
            <Form.Control.Feedback type="invalid">
              Please enter your password.
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="w-100 mb-3"
          disabled={!submitable}
        >
          Sign In
        </Button>
      </Form>
    </>
  );
};

export default SignInForm;
