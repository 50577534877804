import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseResponse } from './response/BaseResponse';

export interface IUserLogin {
  email: string;
  password: string;
}

export interface ITokenData {
  token: string;
  refresh: string;
  exp: number;
  type: string;
}

export interface IPrivilege {
  name: string;
  description: string;
}

export interface IIntrospectResponse {
  active: boolean;
  id: string;
  role: string;
  privileges: IPrivilege[];
}

export interface ITokenRequest {
  token: string;
}

export const authApi = createApi({
  reducerPath: 'auth',
  tagTypes: ['Authentication'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL
  }),
  endpoints: builder => ({
    userLogin: builder.mutation<
      BaseResponse & { data: ITokenData },
      IUserLogin
    >({
      query: data => ({
        url: `/api/v1/auth/login`,
        method: 'POST',
        body: data
      })
    }),
    introspect: builder.mutation<IIntrospectResponse, ITokenRequest>({
      query: data => ({
        url: `/api/v1/auth/introspect`,
        method: 'POST',
        body: data
      })
    })
  })
});

export const { useUserLoginMutation, useIntrospectMutation } = authApi;
